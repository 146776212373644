import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDatepickerModule, NgbPopoverModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PcgSmartyModule } from '@pcg/pcg-shared';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { UiSwitchModule } from 'ngx-ui-switch';

import { MatExpansionModule } from '@angular/material/expansion';
import { AmazingTimePickerModule } from './atp-library/atp-time-picker.module';
import { CollapsibleDividerComponent } from './components/collapsible-divider/collapsible-divider.component';
import { ControlGroupComponent } from './components/control-group/control-group.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DualListBoxComponent } from './components/dual-list-box/dual-list-box.component';
import { DynamicFormFieldComponent } from './components/dynamic-form/dynamic-form-field.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormService } from './components/dynamic-form/dynamic-form.service';
import { FixedButtonsComponent } from './components/fixed-buttons/fixed-buttons.component';
import { PcgSelectComponent } from './components/pcg-select-component/select.component';
import { PinComponent } from './components/pin/pin.component';
import { RadioButtonListComponent } from './components/radio-button-list/radio-button-list.component';
import { ShowHidePasswordComponent } from './components/show-hide-password/show-hide-password.component';
import { ShowHidePinComponent } from './components/show-hide-pin/show-hide-pin.component';
import { StepsComponent } from './components/steps/steps.component';
import { TouchRadioButtonListComponent } from './components/touch-radio-button-list/touch-radio-button-list.component';
import { ClickCursorDirective } from './directives/click-cursor.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ControlRefDirective } from './directives/control-ref.directive';
import { DateValidationDirective } from './directives/date-validation.directive';
import { DragDropDirective } from './directives/drag-drop.directive';
import { InputAutofocusDirective } from './directives/input-autofocus.directive';
import { AbbreviationDirective } from './directives/masking/abbreviation.directive';
import { ComponentNumberDirective } from './directives/masking/component-number.directive';
import { CurrencyDirective } from './directives/masking/currency.directive';
import { CurrencyService } from './directives/masking/currency.service';
import { FeinDirective } from './directives/masking/fein.directive';
import { NdcDirective } from './directives/masking/ndc.directive';
import { NoSpecialCharsDirective } from './directives/masking/no-special-chars.directive';
import { NumericDirective } from './directives/masking/numeric.directive';
import { PercentageDirective } from './directives/masking/percentage.directive';
import { PercentageService } from './directives/masking/percentage.service';
import { PhoneExtDirective } from './directives/masking/phone-ext.directive';
import { PhoneDirective } from './directives/masking/phone.directive';
import { SubmitUrlDirective } from './directives/submit-url.directive';
import { TabsDirective } from './directives/tabs.directive';
import { DragDropFileUploadComponent } from './drag-drop-file-upload/drag-drop-file-upload.component';
import { EditCustomFieldComponent } from './edit-custom-field/edit-custom-field.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { FilterUniquePipe } from './pipes/filter-unique.pipe';
import { SvgModule } from './svg/svg.module';
import { TextEditorComponent } from './text-editor/text-editor.component';

@NgModule({
	declarations: [
		ControlGroupComponent
		, ControlRefDirective
		, CollapsibleDividerComponent
		, PhoneDirective
		, PhoneExtDirective
		, PercentageDirective
		, NdcDirective
		, FeinDirective
		, ClickCursorDirective
		, NoSpecialCharsDirective
		, ComponentNumberDirective
		, ClickOutsideDirective
		, InputAutofocusDirective
		, StepsComponent
		, DateRangeComponent
		, DualListBoxComponent
		, RadioButtonListComponent
		, TouchRadioButtonListComponent
		, FileUploadComponent
		, FixedButtonsComponent
		, NumericDirective
		, CurrencyDirective
		, AbbreviationDirective
		, SubmitUrlDirective
		, DragDropDirective
		, DynamicFormComponent
		, DynamicFormFieldComponent
		, EditCustomFieldComponent
		, FilterUniquePipe
		, PinComponent 
		, DateValidationDirective
		, ShowHidePasswordComponent
		, ShowHidePinComponent
		, DragDropFileUploadComponent
		, PcgSelectComponent
		, TextEditorComponent
		, PageInfoComponent
		, PageTitleComponent
		, TabsDirective
	],
	imports: [
		CommonModule
		, ReactiveFormsModule
		, FormsModule
		, SvgModule
		, NgbDatepickerModule
		, AmazingTimePickerModule
		, CdkStepperModule
		, AngularDualListBoxModule
		, NgbPopoverModule
		, NgSelectModule
		, NgbTooltipModule
		, RouterModule
		, NgbTypeaheadModule
		, PcgSmartyModule
		, UiSwitchModule
		, FontAwesomeModule
		, MatExpansionModule
	],
	exports: [
		ControlGroupComponent
		, ControlRefDirective
		, CollapsibleDividerComponent
		, PhoneDirective
		, PhoneExtDirective
		, NumericDirective
		, CurrencyDirective
		, AbbreviationDirective
		, ComponentNumberDirective
		, PercentageDirective
		, NdcDirective
		, FeinDirective
		, ClickCursorDirective
		, NoSpecialCharsDirective
		, ClickOutsideDirective
		, InputAutofocusDirective
		, StepsComponent
		, SvgModule
		, DateRangeComponent
		, DualListBoxComponent
		, RadioButtonListComponent
		, TouchRadioButtonListComponent
		, FileUploadComponent
		, FixedButtonsComponent
		, SubmitUrlDirective
		, DragDropDirective
		, DynamicFormComponent
		, DynamicFormFieldComponent
		, EditCustomFieldComponent
		, FilterUniquePipe
		, PcgSelectComponent
		, PcgSmartyModule
		, PinComponent
		, DateValidationDirective
		, ShowHidePasswordComponent
		, ShowHidePinComponent
		, DragDropFileUploadComponent
		, TextEditorComponent
		, PageInfoComponent
		, PageTitleComponent
		, TabsDirective
	],
	providers: [
		CurrencyService
		, PercentageService
		, DynamicFormService
	]
})
export class FormElementsModule { }
