import { Component, Input, OnInit } from '@angular/core';
import { UserRoleInfoComponent } from '../user-role-info/user-role-info.component';
import { SystemMessage } from 'app/core/system-message/system-message-service';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';

@Component({
    selector: 'pcg-role-info',
    templateUrl: '../user-role-info/user-role-info.component.html',
    styleUrls: ['../../user-role-info-modal.component.scss']
})
export class RoleInfoComponent extends UserRoleInfoComponent implements OnInit {

    @Input() rolesInput: Array<PermissionRole> = [];

    jsonData: any;
    modalData = [];

    ngOnInit() {
        this.getModalData();
        if (this.rolesInput.length > 0) { this.roles = this.rolesInput; }
    }

    getModalData() {
        this.http.get(`api/Administration/PermissionsInfo/GetPermissionsForModal?module=${this.module}`).subscribe((msg: SystemMessage) => {
            this.jsonData = JSON.parse(msg.model);
            this.modalData = this.roleInfoService.addSecData(this.jsonData)
            
            this.secAccess = [];
            for(var x = 0; x < this.modalData.length; x++) {
                this.secAccess.push(this.roleInfoService.addSec(this.modalData[x]))
            }
        })
    }
}
