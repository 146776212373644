import { NavRoute } from '../shared/navigation/nav.route.interface';
import { getAdminNav } from './nav-areas/admin-nav';
import { getCiRepackNav } from './nav-areas/ci-repack-nav';
import { getHelpDeskNav } from './nav-areas/help-desk-nav';
import { getHEROSNav } from './nav-areas/heros-nav';
import { getIcqNav } from './nav-areas/icq-nav';
import { getInventoryNav } from './nav-areas/inventory-nav';
import { getInvoicesNav } from './nav-areas/invoices-nav';
import { getMetabolicFormulaNav } from './nav-areas/metabolic-formula-nav';
import { getNdcDirectoryNav } from './nav-areas/ndc-directory-nav';
import { getPcgNav } from './nav-areas/pcg-nav';
import { getPharmacyNav } from './nav-areas/pharmacy-nav';
import { getRxConnectsNav } from './nav-areas/rxconnects-nav';

export const getMainNavRoutes = (): NavRoute[] => [
	getHEROSNav()
	, getPharmacyNav()
	, getCiRepackNav()
	, getNdcDirectoryNav()
	, getInvoicesNav()
	, getMetabolicFormulaNav()
	, getIcqNav()
	, getRxConnectsNav()
	, getHelpDeskNav()
	, getInventoryNav()
	, getAdminNav()
	, getPcgNav()
];
