<div class="modal-header">
	<h5 
		class="modal-title" 
		id="modal-basic-title"
	>{{ modalTitle }}&nbsp;</h5> 
	<h5 
		class="modal-title" 
		id="modal-basic-title"
	>Roles</h5>
	<button 
		class="close" 
		aria-label="Close" 
		(click)="activeModal.dismiss()"
	><span aria-hidden="true">&times;</span></button>
</div>

<div class="d-flex flex-column px-5 m-auto">
	<div [innerHTML]="optionalText" class="mt-2 trix-content"></div>
	
	<!-- Admin -->
	<pcg-role-info
		*ngIf="module === businessAreaEnum.Admin" 
		[module]="businessAreaEnum.Admin"
	></pcg-role-info>

	<!-- CI Repack -->
	<pcg-role-info
		*ngIf="module === businessAreaEnum.CI" 
		[module]="businessAreaEnum.CI"
	></pcg-role-info>

	<!-- HEROS -->
	<pcg-role-info
		*ngIf="module === businessAreaEnum.HEROS" 
		[module]="businessAreaEnum.HEROS"
		[rolesInput]="[permissionRole.User, permissionRole.SystemAdmin]"
	></pcg-role-info>

	<!-- Support -->
	<pcg-role-info
		*ngIf="module === businessAreaEnum.HelpDesk" 
		[module]="businessAreaEnum.HelpDesk"
		[rolesInput]="[permissionRole.User, permissionRole.SystemAdmin]"
	></pcg-role-info>

	<!-- Pharmacy -->
	<pcg-role-info 
		*ngIf="module === businessAreaEnum.Inventory" 
		[module]="businessAreaEnum.Inventory"
		[rolesInput]="[permissionRole.Technician, permissionRole.Pharmacist, permissionRole.SystemAdmin]"
	></pcg-role-info>

	<!-- Metabolic Formula -->
	<pcg-role-info 
		*ngIf="module === businessAreaEnum.MetabolicFormula" 
		[module]="businessAreaEnum.MetabolicFormula"
	></pcg-role-info>

	<!-- NDC's -->
	<pcg-role-info
		*ngIf="module === businessAreaEnum.NDC" 
		[module]="businessAreaEnum.NDC"
	></pcg-role-info>

	<!-- ICQ -->
	<pcg-role-info
		*ngIf="module === businessAreaEnum.ICQ" 
		[module]="businessAreaEnum.ICQ"
		[rolesInput]="[permissionRole.User, permissionRole.SystemAdmin]"
	></pcg-role-info>

	<div class="mx-auto p-3">
		<button 
			type="button" 
			class="btn btn-cancel btn-lg" 
			(click)="activeModal.dismiss()"
		>Cancel</button>
	</div>
</div>
