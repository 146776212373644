<button 
	#delBtn
	*ngIf="!useTrashIcon" 
	type='button' 
	(click)='$event.stopPropagation(); deleteRecord()' 
	class='delete'
>
	<svg 
		version='1.1' 
		xmlns='http://www.w3.org/2000/svg' 
		xmlns:xlink='http://www.w3.org/1999/xlink' 
		viewBox='0 0 300 300'
	>
		<g id='layer1'>
			<path 
				d='M 100,60 L 60,100 L 230,270 L 270,230 L 100,60 z ' 
				id='path4950' 
				style='fill:#e00;fill-opacity:1;'
			/>
			<path 
				d='M 60,230 L 230,60 L 270,100 L 100,270 L 60,230 z ' 
				id='path4952' 
				style='fill:#e00;stroke-opacity:1'
			/>
		</g>
	</svg>
</button>

<!-- Changes X icon to FaTrashCan for new mobile card -->
<button 
	*ngIf="useTrashIcon" 
	class="delete-btn" 
	(click)="$event.stopPropagation(); deleteRecord()"
>
	<fa-icon 
		[icon]="faIconName.faTrashCan" 
		class="trash-can"
	></fa-icon>
</button>