import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';

export const getMetabolicFormulaNav: () => NavRoute = () => {
    return {
        name: 'Metabolic Formula'
        , canActivate: [ AuthGuard ]
        , href: 'metabolic-formula/list'
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.MetabolicFormula ]
                , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
            }
        ]
        , children: [
            {
                name: 'Order List'
                , exact: true
                , path: 'metabolic-formula/list'
                , moduleAccess: [
                    {
                        modules: [ BusinessAreaEnum.MetabolicFormula ]
                        , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
                    }
                ]
            }
            , {
                name: 'Facility List'
                , exact: true
                , path: 'metabolic-formula/metabolic-facilities'
                , moduleAccess: [
                    {
                        modules: [ BusinessAreaEnum.MetabolicFormula ]
                        , permissionRoles: [
                            PermissionRole.UserAdmin
                            , ...SecurityService.setMinRole(PermissionRole.Manager)
                        ]
                    }
                ]
            }
            , {
                name: 'Admin Settings'
                , exact: true
                , path: 'metabolic-formula/admin'
                , moduleAccess: [
                    {
                        modules: [ BusinessAreaEnum.MetabolicFormula ]
                        , permissionRoles: SecurityService.setMinRole(PermissionRole.Manager)
                    }
                ]
            }
        ]
    }
}
