<div class="clearfix mt-3">
    <div class="row header-row">
        <div>
            <h3>Create Support Desk Ticket</h3>
        </div>
        <div>
            <img 
                class='system-logo' 
                src='assets/images/logos/DOH_logo.png' 
            />
        </div>
    </div>
    <hr />

    <form 
        *ngIf="!isSuccess" 
        #myForm 
        (submit)="onSubmit()" 
        [formGroup]="formGroup" 
    >
        <input type="hidden" formControlName="categoryName" />
        <input type="hidden" formControlName="priorityName" />

        <div class="row">
            <div class="col-md-12">
                The Pharmaceutical Forms System (PFS) Support provides a single source of assistance and resolution
                for any PFS-related problems or request.
                This includes providing useful answers regarding access, permissions, and using PFS.
                In addition, the PFS Support is used to report suspected bugs experienced while using PFS and the User
                Portal.

                To reach the PFS Support please submit your Support Desk Ticket request via this form or send email to
                pfssupport&#64;doh.state.fl.us.
                The Support System uses the PFS Support Desk Ticketing System and will assign your email or phone call to a Support Desk Ticket.
                You will receive auto-notification back that lets you know your email has been received, and provides
                you with a Support Desk Ticket number for reference.
            </div>
        </div>
        <hr />

        <div class="row">
            <pcg-control-group 
                class="col-md-4" 
                label="Name"
            >
                <input 
                    type="text" 
                    class="form-control" 
                    formControlName="fullName" 
                    pcgControlRef 
                    maxlength="250" 
                />
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-4" 
                label="Email"
            >
                <input 
                    type="text" 
                    class="form-control email" 
                    formControlName="email" 
                    pcgControlRef
                    (change)="sanitize(model.email, false)" 
                    maxlength="100" 
                />
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-4" 
                label="Phone Number"
            >
                <input 
                    pcgPhoneExt 
                    type="text" 
                    class="form-control phone" 
                    formControlName="phoneNumber" 
                    pcgControlRef 
                />
            </pcg-control-group>
        </div>

        <div class="row">
            <pcg-control-group 
                class="col-md-12" 
                label="Facility"
                labelTooltip="If you are associated to multiple facilities, pick which is most related to your problem, or your primary facility."
            >
                <pcg-select 
                    #facilitySelect 
                    dataSource='api/PublicPfsRegister/PfsFacilities' 
                    [multiple]='false'
                    placeholder='Select a Facility...' 
                    formControlName="facilityId" 
                    pcgControlRef
                    (change)="facilityChange()"
                ></pcg-select>
            </pcg-control-group>
        </div>
        <hr />

        <div class="row">
            <pcg-control-group 
                class="col-md-4" 
                label="Brief Subject Title"
            >
                <input 
                    type="text" 
                    class="form-control" 
                    formControlName="subject" 
                    pcgControlRef 
                    maxlength="200" 
                />
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-4" 
                label="How would you categorize this Support Desk Ticket?"
            >
                <pcg-select 
                    #categorySelect 
                    dataSource='api/PublicPfsHelpTicket/PfsHelpCategories' 
                    [multiple]='false'
                    placeholder='Select a Category...' 
                    formControlName="categoryId" 
                    pcgControlRef
                    (change)="categoryChange()"
                ></pcg-select>
            </pcg-control-group>

            <pcg-control-group 
                class="col-md-4" 
                label="Priority"
                labelTooltip="High: Suspected bugs and login problems. Normal: General support requests. Low: Workflow questions and feature requests."
            >
                <pcg-select 
                    #prioritySelect 
                    dataSource='api/PublicPfsHelpTicket/PfsHelpPriorities' 
                    [multiple]='false'
                    placeholder='Select a Priority...' 
                    formControlName="priorityId" 
                    pcgControlRef
                    (change)="priorityChange()"
                ></pcg-select>
            </pcg-control-group>
        </div>

        <div class="row">
            <pcg-control-group 
                class="col-md-12" 
                label="Description"
            >
                <textarea 
                    class="form-control" 
                    formControlName="description" 
                    pcgControlRef 
                    maxlength="500"
                    rows="5"
                ></textarea>
            </pcg-control-group>
        </div>
        <br />

        <pcg-fixed-buttons>
            <button 
                type="button" 
                class='btn btn-cancel btn-lg'
            >
                <a 
                    [href]="pfsUrl" 
                    style="color: black;"
                >Cancel</a>
            </button>
            <button 
                type='submit' 
                class="btn btn-save btn-lg" 
                [disabled]="emptyCaptcha"
            >Submit</button>
        </pcg-fixed-buttons>

        <pcg-control-group 
            class='form-inline' 
            hidden
        >
            <input 
                id="control-pot" 
                type="checkbox" 
                pcgControlRef 
                formControlName='honeyPot' 
            />
        </pcg-control-group>

        <ngx-invisible-recaptcha 
            #captchaElem 
            [siteKey]="siteKey" 
            (reset)="handleReset()" 
            (ready)="handleReady()"
            (load)="handleLoad()" 
            (success)="handleSuccess($event)" 
            [useGlobalDomain]="false" 
            theme="light"
            type="normal" 
            badge="bottomleft" 
            formControlName="recaptcha"
        ></ngx-invisible-recaptcha>
    </form>
    
    <div *ngIf="isSuccess">
        <p class="success">
            Your Support Desk Ticket has been submitted.
            An email has been sent to BPHP staff notifying them of your Support Desk Ticket.
        </p>
        <div class="alignCenter">
            <button 
                type="button" 
                class='btn btn-cancel btn-lg'
            >
                <a 
                    [href]="pfsUrl" 
                    style="color: black;"
                >Return</a>
            </button>
        </div>
    </div>
</div>