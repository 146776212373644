import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getAdminNav: () => NavRoute = () => {
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.

	//#region Children
	const dashboard : NavRoute = {
		name: 'Dashboard'
		, exact: true
		, path: 'administration/dashboard'
	}

	const organizations : NavRoute = {
		name: 'Organizations'
		, href: 'administration/organizations/organizations-list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ ...sec.setMinRole(p.User) ]
			}
		]
		, children: [
			{
				name: 'Organizations'
				, exact: true
				, path: 'administration/organizations/organizations-list'
			}
			, {
				name: 'Account Update Requests'
				, exact: true
				, path: 'administration/organizations/organization-account-request-queue'
			}
			, {
				name: 'Facilities'
				, path: 'administration/organizations/facility-list'
			}
			, {
				name: 'Medical Directors'
				, exact: true
				, path: 'administration/organizations/medical-directors'
			}
			, {
				name: 'Master Accounts'
				, exact: true
				, path: 'administration/organizations/master-accounts'
			}
		]
	}

	const shipping : NavRoute = {
		name: 'Shipping'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ PermissionRole.SystemAdmin ]
			}
		]
		, href: 'administration/shipping/shipping-accounts'
		, children: [
			{
				name: 'Accounts'
				, exact: true
				, path: 'administration/shipping/shipping-accounts'
			}
			, {
				name: 'Boxes'
				, exact: true
				, path: 'administration/shipping/shipping-boxes'
			}
		]
	}

	const users : NavRoute = {
		name: 'Users'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ ...sec.setMinRole(p.Manager) ]
			}
		]
		, href: 'administration/users'
		, children: [
			{
				name: 'User List'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ ...sec.setMinRole(p.Pharmacist) ]
					}
				]
				, exact: true
				, path: 'administration/users'
			}
			, {
				name: 'Global Audit History'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ ...sec.setMinRole(p.Manager) ]
					}
				]
				, exact: true
				, path: 'administration/global-audit-history'
			}
			, {
				name: 'Login History'
				, path: 'administration/users/login-history'
			}
			, {
				name: 'User Custom Fields'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ PermissionRole.SystemAdmin ]
					}
				]
				, path: 'administration/users/user-custom-fields'
			}
			, {
				name: 'Permissions Info'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.Admin ]
						, permissionRoles: [ PermissionRole.SystemAdmin ]
					}
				]
				, href: 'administration/permissions-info/' + BusinessAreaEnum.Admin
				, path: 'administration/permissions-info/' + BusinessAreaEnum.Admin
				, children : [
					{
						name: 'Admin'
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.Admin
					}
					, {
						name: 'CI Repack'
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.CI
					}
					, {
						name: 'HEROS'
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.HEROS
					}
					, {
						name: 'Metabolic Formula'
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.MetabolicFormula
					}
					, {
						name: "NDC's"
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.NDC
					}
					, {
						name: 'Inventory'
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.Inventory
					}
					, {
						name: 'Rx Connects'
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.RxConnects
					}
					, {
						name: 'Support'
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.HelpDesk
					}
					, {
						name: 'ICQ'
						, exact: true
						, path: 'administration/permissions-info/' + BusinessAreaEnum.ICQ
					}
				]
			}
		]
	} 
	
	const printerMgmt : NavRoute = {
		name: 'Printer Management'
		, path: 'administration/printer-management/printer-list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ PermissionRole.SystemAdmin ]
			}
		]
	}
	
	const sysStatus : NavRoute = {
		name: 'System Status'
		, exact: true
		, path: 'administration/system-statuses'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ PermissionRole.SystemAdmin ]
			}
		]
	} 

	const inventoryConfig : NavRoute = {
		name : 'Inventory Configuration'
		, moduleAccess: [
			{
				modules: [BusinessAreaEnum.Admin]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
			}
		]
		, href: 'administration/inventory-configuration/programs'
		, children : [
			{
				name : 'Programs'
				, path: 'administration/inventory-configuration/programs'
				, featureFlag: true
			}
			, {
				name: 'Wholesale Account Groups'
				, path: 'administration/inventory-configuration/wholesale-account-groups'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: [PermissionRole.SystemAdmin]
					}
				]
			}
			, {
				name: 'Global Formulary'
				, path: 'administration/inventory-configuration/formulary/list'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
			}
			, {
				name: 'Global Formulary Settings'
				, path: 'administration/inventory-configuration/formulary/settings'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
			}
			, {
				name: 'Formularies'
				, path: 'administration/inventory-configuration/formularies/list'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
			}
			, {
				name: 'Suppliers'
				, path: 'administration/inventory-configuration/suppliers/supplier-list'
				, exact: true
				, moduleAccess: [
					{
						modules: [BusinessAreaEnum.Admin]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.Pharmacist)
					}
				]
			}
		]
	}
	
	const quartz : NavRoute = {
		name: 'Quartz History'
		, exact: true
		, path: 'quartz/pcg-history'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ p.SystemAdmin ]
			}
		]
	}

	const verificationSettings : NavRoute = {
		name: 'Verification Settings'
		, exact: true
		, path: 'administration/permissions-info/verification-settings'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ PermissionRole.SystemAdmin ]
			}
		]
	}
	//#endregion

	const children : NavRoute[] = [
		dashboard
		, organizations
		, inventoryConfig
		, shipping
		, users
		, printerMgmt
		, sysStatus
		, quartz
		, verificationSettings
	]

	const adminNavItems : NavRoute = {
		name: 'Admin'
		, href: 'administration/dashboard'
		, children: children
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Admin ]
				, permissionRoles: [ ...sec.setMinRole(p.User) ]
			}
		]
	}

	return adminNavItems;
};
