<br />
<img 
	alt="HOMER - Health Operations & Medical Emergency Response" 
	class='system-logo' 
	src='assets/images/logos/logo.png' 
/>
<br />
<div class='login-container clearfix'>
	<form 
		#myForm 
		(submit)="onSubmit()" 
		[formGroup]="loginForm"
	>
		<div class='row'>
			<pcg-control-group 
				class="col-sm-12" 
				label="Username"
			>
				<input 
					pcgControlRef 
					formControlName="userName" 
					type="text" 
					class="form-control" 
				/>
			</pcg-control-group>
		</div>

		<div class='row'>
			<pcg-show-hide-password
				class="col-sm-12" 
				fcName="password" 
				[parentForm]="loginForm" 
				label="Password" 
				inputCssClass="form-control"
			></pcg-show-hide-password>
		</div>

		<button 
			type="submit" 
			class="btn btn-secondary btn-lg"
		>Login</button>
		<a 
			class='float-right' 
			routerLink='/forgot-password'
		>Forgot your password?</a>
	</form>
</div>
<div 
	*ngIf="showMfa"
	class="mfa-container"
>
	<br>
	<label class="for text-danger">Please Select a Multi-Factor Authentication Type</label>
	<button 
		class="btn btn-outline-dark btn-md mr-2" 
		(click)='fidoMfa();'
	>
		<span>
			<fa-icon 
				[icon]="faUsbDrive" 
				class="mr-2"
			></fa-icon> Device
		</span>
	</button>

	<button 
		class="btn btn-outline-dark btn-md mr-2" 
		(click)='fingerprintMfa();'
	>
		<span>
			<fa-icon 
				[icon]="faFingerprint" 
				class="mr-2"
			></fa-icon> SecuGen								
		</span>
	</button>
</div>
<div *ngIf="showInactiveMessage" class="mt-3 alert alert-warning text-center">You must sign back in because you had a period of inactivity during your session.</div>
<div 
	#footerEl 
	id="divisionFooter"
>Florida Division of Emergency Preparedness and Community Support</div>
