<!-- HEADER -->
<div class="modal-header bg-white rounded">
	<h5 class="modal-title" id="modal-basic-title">
		<a (click)="modal.dismiss()" routerLink='administration/organizations/organization-account-request-queue'>Account Update Request</a>
	</h5>
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<!-- BODY -->
<div class="modal-body rounded p-0">

	<!-- List BODY -->
	<ng-container *ngIf="!selectedRequestUpdate && requests$ | async as requests">
		<div class="p-3 requestItem" *ngFor="let r of requests">
			<!-- In other component, this is an A tag w/ href -->
			<div *ngIf="requests.length > 0"  (click)="openRequest(r.organizationAccountRequestId)">
				<div class="row">
					<div class="col-md-12">
						<div class="row">
							<!-- Font Awesome Icons - 2 col -->
							<div class="col-md-2">
								<div class="text-center">
									<fa-stack ngbTooltip="View Request" class="my-4">
										<fa-icon stackItemSize='2x' [icon]="faCircle" class="requestCircle"></fa-icon>
										<fa-icon stackItemSize='1x' [icon]="faArrowRightArrowLeft" [inverse]="true" class="requestArrows"></fa-icon>
									</fa-stack>
								</div>
							</div>
							<!-- Account Info - 10 col -->
							<div class="col-md-10">
								<div class="row">
									<span *ngIf="!r.isNewRequest" class="pending" >Pending Update</span>
									<span *ngIf="r.isNewRequest" class="new">New</span>
									<div class="col-md-12">
										<small class="font-weight-bold">Account: {{ r.organizationAccount.facilityAccountName.name }}</small>
									</div>
									<div class="col-md-12">
										<small class="text-muted"><i><u>{{ r.organizationAccount.organization.organizationName }}</u></i>
											<span *ngIf="!r.isNewRequest"> has requested changes.</span>
											<span *ngIf="r.isNewRequest"> has requested to add a new account.</span>
										</small>
									</div>
									<div class="col-md-12">
										<small class="text-muted">Click to view account request.</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="requests.length === 0" class="d-flex justify-content-center m-4">
			<span>There are currently no organization account requests to process.</span> 
		</div>
	</ng-container>

	<!-- Selected Request BODY -->
	<ng-container *ngIf="selectedRequestUpdate">
		<pcg-organization-account-update [id]="organizationAccountRequestId" [calledFromNav]="true" (tm)="toggleModal()"></pcg-organization-account-update>
	</ng-container>
</div>
