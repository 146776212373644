import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, Event, NavigationCancel, NavigationError, NavigationStart } from '@angular/router';
import { faPrescriptionBottle, faUser, faClipboardList } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { getMainNavRoutes } from 'app/core/main-nav-routes';
import { SecurityService } from 'app/core/security/security.service';
import { CiWorkstationComponent } from 'app/shared/ci-workstation/ci-workstation.component';
import { FaIconName } from 'app/shared/fa-num-icon/fa-icon-name/fa-icon-name';
import { FaIconPosition, FaPositionUnits } from 'app/shared/fa-num-icon/fa-icon-position/fa-icon-position';
import { FaNumColor } from 'app/shared/fa-num-icon/fa-num-color/fa-num-color.enum';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { HeaderVm } from 'app/shared/generated/Models/HeaderVm';
import { pcgSettings } from 'app/shared/generated/pcg-settings';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { NavDividerCharacter, NavigationService } from 'app/shared/navigation/navigation.service';
import { NotificationNavComponent } from 'app/shared/navigation/notification-nav/notification-nav.component';
import { OrganizationAccountUpdateNavComponent } from 'app/shared/navigation/organization-account-update-nav/organization-account-update-nav.component';
import { TopClickNavComponent } from 'app/shared/navigation/top-click-nav/top-click-nav.component';
import { AccountRequestModalStateService } from 'app/shared/state/account-request-modal-state.service';

@UntilDestroy()
@Component({
	selector: 'pcg-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class TopHeaderComponent implements OnInit, AfterContentInit {

	@ViewChild(TopClickNavComponent, { static: true }) nav: TopClickNavComponent;
	@ViewChild('userNav') userNav: ElementRef;
	@ViewChild('mainHamburger', { static: true }) mainHamburger: ElementRef;

	user: UserJsVm;
	headerVm: HeaderVm;
	notificationModal : NgbModalRef
	navRoutes: NavRoute[];
	currSelectedNavMenu: NavRoute;

	currForceSelect: string; 
	currMainNavMenu: string;
	mode: string = pcgSettings.mode;	
	navDividerCharacter: string = NavDividerCharacter;	

	notificationCount = 0; 
	reconCount = 0;
	ciInvoicesCount = 0;
	icqCount = 0;	
	step1Count = 0; 
	step2Count = 0; 
	step3Count = 0; 
	navigationOffset = 0;

	userNavOpen = false; 
	adminAccess = false;	
	reconNavAccess = false; 
	fulfillmentNavAccess = false;
	invoiceNavAccess = false;
	accountRequestAccess = false;
	icqAccess = false;
	isMobile = false;
	isCiUser = false;
	hasCiWorkstation = false;
	hasCiAccess: boolean

	// font awesome icon
	step1Position: FaIconPosition = new FaIconPosition(null, 13, null, null, FaPositionUnits.percent);
	faPrescriptionBottle = faPrescriptionBottle;
	faUser = faUser;
	faClipboardList = faClipboardList;

	// icon helper references
	faNumColor: typeof FaNumColor = FaNumColor;
	faIconName: FaIconName = new FaIconName();	

	loading = false;
	
	constructor(
		private sec: SecurityService
		, private router: Router
		, private navService: NavigationService
		, private modalService: NgbModal
		, private httpClient: HttpClient
		, private location: Location
		, public arms : AccountRequestModalStateService
	) {
		router.events.subscribe((event: Event) => {
			this.hasCiAccess = this.location?.path()?.includes('ci-repack') 
				&& this.sec?.hasModuleAccess( 
					[ BusinessAreaEnum.CI ]
					, SecurityService.setMinRole(PermissionRole.Technician)
				);
		});

		router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					this.loading = true;
					break;
				}	  
				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					this.loading = false;
					break;
				}
			  	default: { break; }
			}}
		);
	}

	// Close the main navigation when they click outside of it
	// and are not clicking the mobile hamburger button
	@HostListener('document:click', ['$event'])
	clickDoc(event: { target: any; }) {
		if (
			!this.mainHamburger?.nativeElement.contains(event.target)
			&& !this.nav?.elRef?.nativeElement.contains(event.target)
			&& !this.userNav?.nativeElement.contains(event.target)
		) {
			this.navService.setCurrOpenNavMenu('');
			this.userNavOpen = false;
		}
	}

	// Fix the nav offset on resize
	@HostListener('window:resize')
	onResize() { this.fixNavOffset(); }

	ngOnInit() {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))
		) { this.isMobile = true; }

		this.sec.user$.pipe(untilDestroyed(this)).subscribe(user => { this.user = user; });
		// Add navigation change subscriptions
		this.addNavigationSubscriptions();

		// Subscribe to route changes and update the navs when they happen
		this.router.events
			.pipe(untilDestroyed(this))
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => {
				this.fixNavOffset();
				this.navService.navRoutes.next(this.sec.getSecureNavItems(getMainNavRoutes()));
				this.getHeaderCounts();
				if (!this.location.path().includes('/job-sheet/')){ this.getCiWorkstation(); }
			});
	}

	addNavigationSubscriptions() {
		this.navService.navRoutes$.pipe(untilDestroyed(this))
			.subscribe(n => { this.navRoutes = this.navService.getFlatMenu(n).filter(o => !o.parentNav); });
		this.navService.currOpenPrimaryNavMenu$.pipe(untilDestroyed(this))
			.subscribe(c => { this.currMainNavMenu = c; });
		this.navService.currForceSelect$.pipe(untilDestroyed(this))
			.subscribe(currForceSelect => { this.currForceSelect = currForceSelect; });

		this.navService.currSelectedMainNavMenu$.pipe(untilDestroyed(this)).subscribe(c => {
			this.currSelectedNavMenu = c;
			if (
				!c 
				|| c.id === 'root'
			) { this.navService.setOpenSecondaryMenu([]); }
		});

		this.navService.currOpenSecondaryNavMenu$.pipe(untilDestroyed(this)).subscribe(c => {
			this.currSelectedNavMenu = c;
			this.fixNavOffset();
		});
	}

	getCiWorkstation() {
		// Check if is a CI user
		this.isCiUser = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.CI ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		if (this.isCiUser === true) {
			const opened = localStorage.getItem('ciWorkstationModalOpened');
			const sessionToken = localStorage.getItem('jwt');
			this.hasCiWorkstation = false;
			if (
				opened !== sessionToken 
				&& this.user.isInternalBphpUser
				&& !this.user.email.includes("@paulconsulting.com")
			) { this.openCiWorkstationModal(); }
			localStorage.setItem('ciWorkstationModalOpened', sessionToken);
		}
	}

	getHeaderCounts() {
		this.fulfillmentNavAccess = this.reconNavAccess 
			= this.sec?.hasModuleAccess(
				[ BusinessAreaEnum.Inventory ]
				, SecurityService.setMinRole(PermissionRole.Technician)
			);
		this.invoiceNavAccess = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Inventory ]
			, SecurityService.setMinRole(PermissionRole.Technician)
		);
		this.accountRequestAccess = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Manager)
		);
		this.icqAccess = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.ICQ ]
			, SecurityService.setMinRole(PermissionRole.User)
		);

		const step1: number = + sessionStorage.getItem('step1Count');
		const step2: number = + sessionStorage.getItem('step2Count');
		const step3: number = + sessionStorage.getItem('step3Count');
		const opened = sessionStorage.getItem('notificationModalOpened');
		
		if (
			this.fulfillmentNavAccess 
			|| this.reconNavAccess 
			|| this.invoiceNavAccess
			|| this.icqAccess
		) {
			this.httpClient.get(`api/Header/GetHeaderCounts
				?reconAccess=${this.reconNavAccess}
				&rxAccess=${this.fulfillmentNavAccess}
				&invoiceAccess=${this.invoiceNavAccess}
				&icqAccess=${this.icqAccess}`
			).subscribe((vm: HeaderVm) => {
				sessionStorage.setItem('step1Count', vm.fulfillmentStep1ProductPrepCount.toString());
				sessionStorage.setItem('step2Count', vm.fulfillmentStep2ShippingCount.toString());
				sessionStorage.setItem('step3Count', vm.fulfillmentStep3QCCount.toString());
				this.notificationCount = 0;

				this.step1Count = vm.fulfillmentStep1ProductPrepCount;
				this.step2Count = vm.fulfillmentStep2ShippingCount;
				this.step3Count = vm.fulfillmentStep3QCCount;

				this.notificationCount += vm.reconciliationCount;
				this.notificationCount += vm.ciInvoicesCount;
				this.notificationCount += vm.icqNewSurveyCount;
				this.notificationCount += vm.icqOverdueSurveyCount;
				this.notificationCount += vm.icqPostSiteVisitCount;
				this.notificationCount += vm.icqScheduleSiteVisitCount;
				this.notificationCount == vm.icqSurveysToCompleteCount;

				this.reconCount = vm.reconciliationCount;
				this.ciInvoicesCount += vm.ciInvoicesCount;
				this.icqCount += vm.icqNewSurveyCount;
				this.icqCount += vm.icqOverdueSurveyCount;
				this.icqCount += vm.icqPostSiteVisitCount;
				this.icqCount += vm.icqScheduleSiteVisitCount;
				this.icqCount += vm.icqSurveysToCompleteCount;
				
				if (this.notificationCount > 0) {
					if (
						opened !== 'true' 
						&& this.user?.receiveReconciliationNotifications 
						&& (
							this.isCiUser !== true 
							|| this.hasCiWorkstation === true
						)
					) { setTimeout(() => { this.openNotificationModal(); }, 100); }						
				}
				sessionStorage.setItem('notificationModalOpened', 'true');
			});

			if (!this.fulfillmentNavAccess) {
				this.step1Count = step1;
				this.step2Count = step2;
				this.step3Count = step3;
			}
		} else {
			this.step1Count = step1;
			this.step2Count = step2;
			this.step3Count = step3;
		}
	}

	// Logout removes all of our security variables and redirects user to root
	logout() {
		this.sec.setSecurity(null, null);
		this.deleteAllCookies();
		this.router.navigate(['/']);
		sessionStorage.removeItem('notificationModalOpened');
		sessionStorage.removeItem('step1Count');
		sessionStorage.removeItem('step2Count');
		sessionStorage.removeItem('step3Count');
		sessionStorage.removeItem('updateRequestCount');
		sessionStorage.removeItem('ciWorkstationModalOpened');
		localStorage.removeItem('herosAgencyFilters');
		localStorage.removeItem('herosApplicantFilters');
	}

	// Checks if user can view Admin Dashboard
	checkAccess(setUserNav = false) { 
		this.adminAccess = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Manager)
		); 
		if (setUserNav === true) { this.userNavOpen = true; }
	}

	/** Handle clicking any link in the user navigation **/
	clickUserLink() { this.userNavOpen = false; }

	// This is used above to delete all cookies on logout
	deleteAllCookies() {
		const cookies = document?.cookie?.split(';');

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 
				? cookie.substr(0, eqPos) 
				: cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
		}
	}

	// Toggle whether or not the main navigation is open
	toggleNav() {
		if (this.currMainNavMenu === '') { this.navService.setCurrOpenNavMenu('root'); } 
		else { this.navService.setCurrOpenNavMenu(''); }
	}

	openOrgAccountUpdateModal = () =>  
		// Stores modal reference in state of the service.
		this.arms.setModalRef(this.modalService.open(OrganizationAccountUpdateNavComponent, { animation: false })); 

	openNotificationModal() { 
		this.notificationModal = this.modalService.open(NotificationNavComponent, { animation: false }); 
		this.notificationModal.componentInstance.modalRef = this.notificationModal;
		this.notificationModal.componentInstance.icqCount = this.icqCount;
		this.notificationModal.componentInstance.ciInvoiceCount = this.ciInvoicesCount;
	}

	openCiWorkstationModal() { this.modalService.open(CiWorkstationComponent, { animation: false }); }
	openFulfillmentList(step: number) { window.location.href = '/fulfillment/fulfillment?status=' + step; }		

	// Fix the nav offset on content init
	ngAfterContentInit() { this.fixNavOffset(); }

	/** This adds dynamic CSS based on the navigation size.
	 * Specifically, it does the following:
	 * 1. Adds padding to the top of the page so nothing is hidden by the fixed nav
	 * 2. Sets the top offset for our PCG table sticky headers.
	 */
	fixNavOffset() {
		const mainNav = document.getElementById('pcgMainNavigation');
		setTimeout(() => {
			if (getComputedStyle(mainNav, null).position === 'fixed') {
				this.navigationOffset = mainNav.getBoundingClientRect().bottom;
			} else { this.navigationOffset = 0; }

			const styles = `
				pcg-side-nav {
					top: ${this.navigationOffset - 1}px
				}` 
				+ `.nav-padding-placeholder {
					padding-top: ${this.navigationOffset}px;
				}` 
				+ `.conversations-sidenav {
					top: ${this.navigationOffset}px
				}` 
				+ `.knowledge-base-doc-popout {
					top: ${this.navigationOffset}px
				}` 
				+ `.popout {
					top: ${this.navigationOffset}px
				}`;

			const tableStyles = `
				.pcg-table-fixed-header thead tr th, .pcg-table-fixed-header thead tr {
					top: ${this.navigationOffset - 1}px
				}`;

			this.setDynamicStyle('navPaddingOffset', styles);
			this.setDynamicStyle('navTableOffset', tableStyles);
		}, 100);
	}

	setDynamicStyle(name: string, styles: string) {
		let dynamicStyle = document.getElementById(name);
		if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		dynamicStyle = document.createElement('style');
		dynamicStyle.id = name;
		dynamicStyle.innerHTML = styles;
		document.head.appendChild(dynamicStyle);
	}
}
