import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

import { UserRoleInfoModalComponent } from 'app/administration/users/edit-user/user-role-info-modal/user-role-info-modal.component';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { ModuleAccessVm } from 'app/shared/generated/Administration/Models/Security/ModuleAccessVm';
import { UserModulesVm } from 'app/shared/generated/Administration/Models/Security/UserModulesVm';
import { NavigationService } from 'app/shared/navigation/navigation.service';

@Component({
    selector: 'pcg-user-module-access',
    templateUrl: './user-module-access.component.html',
    styleUrls: ['./user-module-access.component.scss']
})
export class UserModuleAccessComponent implements OnInit {

    isSysAdmin = false;
    allModulesAssigned: boolean;
    businessAreaEnum = BusinessAreaEnum;

    // Only used until we have integrated new security throughout the system. Terry R. 9/8/22
    availableModules: BusinessAreaEnum[] = [
        BusinessAreaEnum.Admin
        , BusinessAreaEnum.CI
        , BusinessAreaEnum.HEROS
        , BusinessAreaEnum.ICQ
        , BusinessAreaEnum.Inventory
        , BusinessAreaEnum.MetabolicFormula
        , BusinessAreaEnum.NDC
        , BusinessAreaEnum.RxConnects
        , BusinessAreaEnum.HelpDesk
        , BusinessAreaEnum.SysInv
    ];

    model$: Observable<UserModulesVm>;
    model: UserModulesVm;
    userId: number;
    formGroup = UserModulesVm.Form;
    subscriptions = new Subscription();

    constructor(
        title: Title
        , private route: ActivatedRoute
        , private httpClient: HttpClient
        , private security: SecurityService
        , private modalService: NgbModal
        , private nav: NavigationService
    ) { title.setTitle('User Module Access'); }

    ngOnInit() {
        this.userId = parseInt(this.route.snapshot.params['id']);
        this.isSysAdmin = this.security.hasModuleAccess(
            [BusinessAreaEnum.Admin]
            , SecurityService.setMinRole(PermissionRole.SystemAdmin)
        );

        this.model$ = this.httpClient.get(`api/Administration/ModuleAccess/GetModuleAccessByUser?userId=${this.userId}`)
            .pipe(tap((model: UserModulesVm) => this.updateForm(model)));
    }

	canEditModuleAccess = (module: BusinessAreaEnum, role: PermissionRole) => this.security
        .hasModuleAccess([BusinessAreaEnum.Admin], [PermissionRole.SystemAdmin]) 
        || (
            this.security.hasModuleAccess(
                [module], [PermissionRole.UserAdmin, ...SecurityService.setMinRole(PermissionRole.Manager)]
            ) 
            && this.security.hasModuleAccess([module], SecurityService.setMinRole(role))
        );

    updateForm(model: UserModulesVm) {
        this.model = model;
        this.formGroup.patchValue(model);
        this.getAvailableModules();
        
        // Update security and navigation when changing module access for self.
        if (this.userId === this.security.getUser().id) {
            var user = this.security.getUser();
            user.moduleAccess = model.moduleAccess;
            this.security.setSecurity(undefined, user);
            this.nav.setActiveNav(['Admin', 'Users', 'User List']);
        }
    }

    openRoleInfoModal(module: BusinessAreaEnum) {
        const modalRef = this.modalService.open(UserRoleInfoModalComponent, { size: 'xl' });
        modalRef.componentInstance.module = module;
    }

    // Populate all modules that implement module access security.
    getAvailableModules() {
        Object.keys(BusinessAreaEnum)
            .filter(value => isNaN(Number(value)) === false
                && !this.model.moduleAccess.find(m => m.module == value)
                && this.availableModules.includes(Number(value)))
            .forEach(key => this.addModuleAccess(key));

        // Sort alphabetically
        this.model.moduleAccess.sort((a, b) => { 
            return (a.displayName.toUpperCase() < b.displayName.toUpperCase()) 
                ? -1 
                : (a.displayName.toUpperCase() > b.displayName.toUpperCase()) 
                    ? 1 
                    : 0;
        });
        this.formGroup.patchValue(this.model);
    }

    addModuleAccess(module) {
        if (this.isSysAdmin) {
            this.model.moduleAccess.unshift(Object.assign(new ModuleAccessVm(), {
                moduleAccessId: 0,
                userId: this.model.userId,
                module: Number(module),
                permissionRole: PermissionRole.NoAccess, 
                displayName: this.getDisplayName(Number(module))
            }));
        }
    }

    getDisplayName(businessArea: BusinessAreaEnum) { return this.businessAreaEnum.toDisplay(businessArea); }
    submitForm() { (document.querySelectorAll('form')[document.querySelectorAll('form').length - 1] as HTMLFormElement).requestSubmit(); }
}