<div 
	*ngIf="mustActivateMfaAndInactivity" 
	class="alert alert-danger text-center"
>
	The area you are trying to access contains protected health information. 
	You must enroll a multi-factor authentication device or fingerprint to continue.
</div>

<pcg-page-title
	primaryTitle="{{formGroup.value.firstName }} {{ formGroup.value.lastName}}"
	[backBtnLink]="canManageUsers ? '/administration/users' : null"
	backBtnTooltip="Return to Global User List">
</pcg-page-title>

<mat-tab-group pcgTabs 
	mat-stretch-tabs='false' 
	mat-align-tabs='start' 
	animationDuration='200ms' 
	[preserveContent]='true'
>
	<!-- Details -->
	<mat-tab label="Details">
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<form 
					pcgSubmitUrl="api/Administration/Users/SaveUser" 
					(messageReceive)="saveUser($event)"
					[formGroup]="formGroup" 
				>
					<div>
						<input type="hidden" formControlName="userId"/>

						<div class="row">
							<div 
								id="activation" 
								class="col-md-12"
							>
								<div 
									*ngIf="
										isLockedOut 
										&& canUnlock
									" 
									id="divUnlockUser" 
									(click)="unlockUser()"
								>
									<fa-icon
										[icon]="faIconName.faUnlock" 
										class="unlockIcon fa-2x"
										ngbTooltip="This user is locked out. Click here to unlock the user and send them an email with login instructions."
									></fa-icon>
									<span class="unlockLable">Locked Out (Click to unlock)</span>
								</div>

								<div class="activate">
									<p 
										[class.text-danger]="!model.isActive" 
										[class.d-none]="model.isActive"
										class="mr-2 mt-2 mb-0"
									>This user is deactivated.</p>
									<a 
										*ngIf="
											(
												!isCurrentUser 
												&& canEdit
											) 
											&& model.userId !== 0
										" 
										[class.btn-danger]="model.isActive" 
										[class.btn-save]="!model.isActive" 
										class="btn btn-sm" 
										(click)="activateDeactivate();"
									>{{ model.isActive ? 'Deactivate' : 'Reactivate' }}</a>
									<input 
										*ngIf="model.userId === 0" 
										type="hidden" 
										formControlName="isActive" 
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<pcg-control-group 
								class="col-md-4" 
								label="First Name"
							>
								<input 
									pcgControlRef 
									class="form-control" 
									type="text" 
									formControlName="firstName"
								/>
							</pcg-control-group>

							<pcg-control-group 
								class="col-md-4" 
								label="Last Name"
							>
								<input 
									pcgControlRef 
									class="form-control" 
									type="text" 
									formControlName="lastName"
								/>
							</pcg-control-group>

							<pcg-control-group 
								*ngIf="model.userId === 0" 
								class="col-md-4" 
								label="Username"
							>
								<input 
									pcgControlRef 
									class="form-control" 
									type="text" 
									formControlName="userName"
								/>
							</pcg-control-group>
						</div>

						<div class="row">
							<pcg-control-group 
								class="col-md-4" 
								label="Email"
							>
								<input
									pcgControlRef 
									class="form-control email" 
									type="text" 
									formControlName="email"
								/>
							</pcg-control-group>

							<pcg-control-group 
								class="col-md-4" 
								label="Phone" 
								[validations]="phoneValidation"
							>
								<input 
									pcgPhoneExt 
									pcgControlRef 
									class="form-control" 
									type="text" 
									formControlName="phoneNumber"
								/>
							</pcg-control-group>

							<div 
								*ngIf="canSeePasswordButtons" 
								class="col-md-4"
							>
								<input
									*ngIf="canSendWelcomeEmail"
									type="button"
									value="Send Welcome Email"
									id="btnSendWelcomeMessage"
									class="btn btn-save btn-sm"
									ngbTooltip="Send user a welcome email with their username and the ability to choose a new password."
									(click)="resetPassword(true)"
								/>
							</div>
						</div>
					</div>

					<div>
						<h4>Security</h4>
						<hr />

						<div class="row">
							<pcg-control-group 
								class="col-md-12" 
								label="Inventories" 
								labelTooltip="Users with no Inventory associations can see information for all Inventories.
								Users with Inventory associations can only see information for their own Inventories."
							>
								<pcg-select 
									[sendFormData]="false" 
									pcgControlRef [multiple]="true"
									dataSource="api/Select/Inventories" 
									formControlName="inventoryIds"
									itemsShowLimit="3"
									[selectedItemIds]="formGroup.controls.inventoryIds.value"
								></pcg-select>
							</pcg-control-group>
						</div>
					</div>

					<div 
						*ngIf="customFieldsCount > 0" 
						class="container"
					>
						<br /><br />
						<h4>Custom Fields</h4>
						<hr />
						<pcg-dynamic-form 
							[fields]="fields" 
							[fg]="formGroup" 
							fgName="customFields"
						></pcg-dynamic-form>
					</div>

					<pcg-fixed-buttons class="mt-3">
						<button class="btn btn-save btn-lg">{{ id === 0 ? "Create" : "Save" }}</button>
					</pcg-fixed-buttons>
				</form>
			</div>
		</ng-template>
	</mat-tab>

	<!-- Organizations -->
	<mat-tab 
		*ngIf="id !== 0" 
		label="Organizations"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-user-organizations [userId]="id"></pcg-user-organizations>
			</div>
		</ng-template>
	</mat-tab>

	<!-- Facilities -->
	<mat-tab 
		*ngIf="id !== 0" 
		label="HEROS Agencies"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-user-facilities
					[id]="id"
					[editUserVm]="model"
				></pcg-user-facilities>
			</div>
		</ng-template>
	</mat-tab>

	<!-- Security -->
	<mat-tab 
		*ngIf="id !== 0" 
		label="Security"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<!-- This component is in the user-multi-factor-authentication directory under the edit-user component's directory -->
				<pcg-user-multi-factor-authentication
					[id]="id"
					[pin]="pin"
					[editUserVm]="model"
					[formGroup]="formGroup"
					[isCurrentUser]="isCurrentUser"
					[canManageUsers]="canManageUsers"
					[canResetPassword]="canResetPassword"
					[inactivityTimeoutFg]="inactivityTimeoutFg"
					[isInternalBphpUserFg]="isInternalBphpUserFg"
				></pcg-user-multi-factor-authentication>
			</div>
		</ng-template>
	</mat-tab>

	<!-- Notifications -->
	<mat-tab 
		*ngIf="id !== 0" 
		label="Notifications"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-user-email-settings [id]="id"></pcg-user-email-settings>
			</div>
		</ng-template>
	</mat-tab>

	<!-- Security Roles -->
	<mat-tab 
		*ngIf="
			id !== 0 
			&& canViewSecurity
		" 
		label="Module Access"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-user-module-access [id]="id"></pcg-user-module-access>
			</div>
		</ng-template>
	</mat-tab>

	<!-- Login History -->
	<mat-tab 
		*ngIf="
			id !== 0 
			&& canViewLoginHistory
		" 
		label="Login History"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<!-- This component is in the user-login-history directory under the edit-user component's directory -->
				<pcg-user-login-history [id]="id"></pcg-user-login-history>
			</div>
		</ng-template>
	</mat-tab>

	<!-- Audit History -->
	<mat-tab 
		*ngIf="
			id !== 0 
			&& canViewAuditHistory
		" 
		label="Audit History"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-user-audit-history [id]="id"></pcg-user-audit-history>
			</div>
		</ng-template>
	</mat-tab>

	<!-- RxConnects -->
	<mat-tab 
		*ngIf="
			id !== 0 
			&& canEdit
		" 
		label="RxConnects"
	>
		<ng-template matTabContent>
			<div class="mat-tab-container">
				<pcg-user-rxconnects-settings [id]="id"></pcg-user-rxconnects-settings>
			</div>
		</ng-template>
	</mat-tab>
</mat-tab-group>