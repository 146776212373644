import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'pcg-confirmation-comment-modal',
  standalone : true,
  imports: [FormsModule],
  templateUrl: './confirmation-comment-modal.component.html',
  styleUrl: './confirmation-comment-modal.component.scss'
})
export class ConfirmationCommentModalComponent {
    @Input() confirmationMessage: string = 'You must leave a comment to continue?';
    @Input() showConfirmBtn: boolean = true;
    @Input() error: boolean = false;

    comment : string;

    modalRef: NgbModalRef;

    confirm() { this.modalRef.close(this.comment); }
    cancel() { this.modalRef.close(false); }
}
