import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getNdcDirectoryNav: () => NavRoute = () => {
	return {
		name: 'NDC\'s'
		, href: 'redbook/redbook-list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.NDC ]
				, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
			}
		]
		, children: [
			{
				name: 'NDC Directory'
				, exact: true
				, path: 'ndc-directory/ndc-directory-list/'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.NDC ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
			}
			, {
				name: 'Red Book'
				, exact: true
				, path: 'redbook/redbook-list'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.NDC ]
						, permissionRoles: SecurityService.setMinRole(PermissionRole.User)
					}
				]
			}
		]
	};
};
