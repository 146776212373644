<br />
<div class="login-container clearfix">
    <div class="row headerRow">
        <div class="col-md-4 alignLeft">
            <br />
            <img 
                alt="" 
                class='system-logo' 
                src='assets/images/logos/DOH_logo.png' 
            />
            <br /><br />
        </div>
        <div class="col-md-4 alignCenter p-5 mt-4">
            <h3>PFS Registration</h3>
        </div>
        <div class="col-md-4 alignRight">
            <br />
            <img 
                alt="" 
                class='system-logo' 
                src='assets/images/logos/PFS_logo.png' 
            />
            <br /><br />
        </div>
    </div>
    <hr />

    <form 
        *ngIf="!isSuccess" 
        #myForm 
        (submit)="onSubmit()" 
        [formGroup]="formGroup" 
        class=""
    >
        <div class="row">
            <div class="col-md-12">
                Select your facilities from the dropdown list below.
                Typing into the text area will filter your results.
                Once you find your facility select it with a mouse click or by highlighting it with the arrow keys and
                pressing enter.
                Your facility will then be added to your list.
                Once an item is selected you may remove it by clicking the "x" to the left.
                Repeat the process to select more than one facility.
            </div>
        </div>
        <br />

        <div class="row">
            <div class="col-md-12">
                If you cannot find your facility click 
                <a 
                    [href]="'/pfs-help-ticket'" 
                    target="_blank" 
                    class="hyperlink"
                >here</a> 
                a to submit a Support Desk Ticket for assistance.
            </div>
        </div>
        <br />

        <div class="row">
            <pcg-control-group 
                class="col-md-12" 
                label="Facilities">
                <pcg-select 
                    dataSource='api/PublicPfsRegister/PfsFacilities' 
                    [multiple]='true'
                    placeholder='Select Facilities...' 
                    formControlName="facilityIds" 
                    pcgControlRef
                    itemsShowLimit="2"
                    [selectedItemIds]="formGroup.controls.facilityIds.value"
                ></pcg-select>
            </pcg-control-group>
        </div>
        <br />

        <div class="row">
            <pcg-control-group class="col-md-6" label="First Name">
                <input 
                    type="text" 
                    class="form-control" 
                    formControlName="firstName" 
                    pcgControlRef 
                    maxlength="50" 
                />
            </pcg-control-group>

            <pcg-control-group class="col-md-6" label="Last Name">
                <input 
                    type="text" 
                    class="form-control" 
                    formControlName="lastName" 
                    pcgControlRef 
                    maxlength="50" 
                />
            </pcg-control-group>
        </div>
        <br />

        <div class="row">
            <pcg-control-group class="col-md-6" label="Email">
                <input 
                    type="text" 
                    class="form-control email" 
                    formControlName="email" 
                    pcgControlRef
                    (change)="sanitize(model.email, false)" 
                    maxlength="100" 
                />
            </pcg-control-group>

            <pcg-control-group class="col-md-6" label="Confirm Email">
                <input 
                    type="text" 
                    class="form-control email" 
                    formControlName="confirmEmail" 
                    pcgControlRef
                    (change)="sanitize(model.confirmEmail, true)" 
                    maxlength="100"     
                />
                <span 
                    id="lblConfirmEmail" 
                    class="text-danger ng-star-inserted" 
                    [class.d-none]="emailMatch"
                > Emails do not match</span>
            </pcg-control-group>
        </div>
        <br />

        <div class="row">
            <pcg-control-group class="col-md-6" label="Phone Number">
                <input 
                    pcgPhoneExt 
                    type="text" 
                    class="form-control" 
                    formControlName="phoneNumber" 
                    pcgControlRef 
                />
            </pcg-control-group>

            <div class="col-md-3 alignRight">
                <br />
                <button 
                    type='submit' 
                    class="btn btn-save btn-lg" 
                    [disabled]="emptyCaptcha"
                >Submit</button>
            </div>

            <div class="col-md-3 alignLeft">
                <br />
                <button 
                    type="button" 
                    class='btn btn-cancel btn-lg'
                >
                    <a 
                        [href]="pfsUrl" 
                        style="color: black;"
                    >Cancel</a>
                </button>
            </div>
        </div>

        <pcg-control-group class='form-inline' hidden>
            <input 
                id="control-pot" 
                type="checkbox" 
                pcgControlRef 
                formControlName='honeyPot' 
            />
        </pcg-control-group>

        <ngx-invisible-recaptcha 
            #captchaElem 
            [siteKey]="siteKey" 
            (reset)="handleReset()" 
            (ready)="handleReady()"
            (load)="handleLoad()" 
            (success)="handleSuccess($event)" 
            [useGlobalDomain]="false" 
            theme="light"
            type="normal" 
            badge="bottomleft" 
            formControlName="recaptcha"
        ></ngx-invisible-recaptcha>
    </form>

    <div *ngIf="isSuccess">
        <p class="success">
            Congratulations, you have successfully registered for access to PFS!
            A confirmation email has been sent to you.
            An email has been sent to BPHP staff notifying them of your registration.
            BPHP staff will review, setup, and confirm your registration.
            Once your registration has been confirmed, you will receive an email with your Username, and a link to
            create your password.
        </p>
        <div class="alignCenter">
            <button 
                type="button" 
                class='btn btn-cancel btn-lg'
            >
                <a 
                    [href]="pfsUrl" 
                    style="color: black;"
                >Return</a>
            </button>
        </div>
    </div>
</div>

<br />