<pcg-page-info>
	This section will list all system notifications that you may receive.
	Certain notifications are required and are not displayed below.
	If you wish to receive push notifications to your browser and/or mobile device,
	you must first enable push notifications on each device with the toggle below.
</pcg-page-info>

<div 
	*ngIf="iAmMe" 
	class="notification-row"
>
	<mat-slide-toggle 
		id="pushToggle" 
		class="mr-3" 
		[checked]="pushNotificationsEnabled" 
		[disabled]="disablePushToggle"
		(click)="operatePushNotification()"
	></mat-slide-toggle>
	Enable &nbsp;
	<span ngbTooltip="Push notifications consist of both mobile and browser notifications">
		push notifications
	</span>&nbsp; on this device
</div>

<form 
	*ngIf="model$ | async" 
	[formGroup]="formGroup" 
	class='p-4'
>

	<input type='hidden' formControlName='userEmailSettingId' />
	<input type='hidden' formControlName='userId' />

	<!-- All notifications -->
	<div class="notification-row nbb">
		<div class="notification-toggle">
			<mat-button-toggle-group>
				<mat-button-toggle 
					[value]="0" 
					(click)="selectForAll(0)"
					style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
				>Off</mat-button-toggle>
				<mat-button-toggle 
					[value]="1" 
					(click)="selectForAll(1)"
				>Email</mat-button-toggle>
				<mat-button-toggle 
					[value]="2" 
					(click)="selectPushForAll()"
				>Push</mat-button-toggle>
				<mat-button-toggle 
					[value]="3" 
					(click)="selectBothForAll()"
				>Both</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div class="notification-head">
			<h6>All Notifications</h6>
		</div>

		<div class="notification-body">
			The selection made here will apply to every notification below.
			Because Push notifications are currently only available for Support notifications,
			if you select the Push or Both option here it will apply that selection to the Support
			notifications and reset the rest of your notifications.
		</div>
	</div>

	<!-- Fulfillment section -->
	<div *ngIf="hasFulfillmentAccess">
		<div class="notification-row notification-section-head">
			<h5>Pharmacy</h5>
		</div>

		<div 
			class="notification-row" 
			[ngClass]="{ nbb: isOverdue1 }"
		>
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="fulfillmentOverdue1"
					[value]="this.formGroup.value.fulfillmentOverdue1" 
					(change)="overdue1Change()"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>First Overdue Fulfillment</h6>
			</div>

			<div class="notification-body">
				Receive daily notifications regarding Rx Fulfillments that have surpassed the first hourly time limit.
				For example, if a fulfillment has been created and has not been completed within the first overdue time
				limit.
			</div>
		</div>

		<div 
			*ngIf="isOverdue1" 
			class="notification-row"
		>
			<div class="mr-3">Choose inventories to receive notifications:</div>
			<pcg-select 
				pcgControlRef 
				[multiple]="true" 
				dataSource="api/Select/Inventories"
				formControlName="fulfillmentOverdueFirstInventoryIds"
				itemsShowLimit="2"
				[selectedItemIds]="formGroup.controls.fulfillmentOverdueFirstInventoryIds.value"
			></pcg-select>
		</div>

		<div class="notification-row nbb">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="fulfillmentOverdue2"
					[value]="this.formGroup.value.fulfillmentOverdue2" 
					(change)="overdue2Change()"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Second Overdue Fulfillment</h6>
			</div>

			<div class="notification-body">
				Receive daily notifications regarding Rx Fulfillments that have surpassed the second hourly time limit.
				For example, if a fulfillment has been created and has not been completed within the second overdue time
				limit.
			</div>
		</div>

		<div 
			*ngIf="isOverdue2" 
			class="notification-row nbb"
		>
			<div class="mr-3">Choose inventories to receive notifications:</div>
			<pcg-select 
				pcgControlRef 
				[multiple]="true" 
				dataSource="api/Select/Inventories"
				formControlName="fulfillmentOverdueSecondInventoryIds"
				itemsShowLimit="2"
				[selectedItemIds]="formGroup.controls.fulfillmentOverdueSecondInventoryIds.value"
			></pcg-select>
		</div>

		<div 
			*ngIf="hasReconsAccess" 
			class="notification-row nbb"
		>
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="reconciliationEmails"
					[value]="this.formGroup.value.reconciliationEmails"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Incomplete Reconciliations</h6>
			</div>

			<div class="notification-body">
				Receive daily notifications with reconciliations in a cycle count that are incomplete and behind
				schedule.
			</div>
		</div>

		<div 
			*ngIf="hasReplenishmentAccess" 
			class="notification-row"
		>
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="reorderLowInventory"
					[value]="this.formGroup.value.reorderLowInventory"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Reorder Low Inventory</h6>
			</div>

			<div class="notification-body">
				Receive daily notifications regarding inventory products that are low in stock and are in need of
				reordering.
			</div>
		</div>

		<div 
			*ngIf="hasReplenishmentAccess" 
			class="notification-row nbb"
		>
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="overrideReorderSettings"
					[value]="this.formGroup.value.overrideReorderSettings"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Override Reorder Settings</h6>
			</div>

			<div class="notification-body">
				Receive notifications when an inventory product's reorder settings have been overridden.
			</div>
		</div>

		<div class="notification-row nbb">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="inventoryChange"
					[value]="this.formGroup.value.inventoryChange"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Change Notifications</h6>
			</div>

			<div class="notification-body">
				Receive notifications when a +/- 10% change in inventory is completed via the Update Inventory modal.
			</div>
		</div>
	</div>

	<!-- HEROS section -->
	<div *ngIf="hasHerosAccess">
		<div 
			id="heros" 
			class="notification-row notification-section-head"
		>
			<h5>HEROS</h5>
		</div>

		<div class="notification-row nbb">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="herosPendingAgencyEmails"
					[value]="this.formGroup.value.herosPendingAgencyEmails"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6 ngbTooltip="User must have full access to HEROS Delete Agency and Historic Application security permission to mangage this notification.">
					Pending Agency Emails
				</h6>
			</div>

			<div class="notification-body">
				Receive notifications on weekdays at 6am with a list of all HEROS Agencies that are pending initial
				approval.
			</div>
		</div>
	</div>

	<!-- Metabolic Formula section -->
	<div *ngIf="hasMetabolicFormulaAccess">
		<div 
			id="metabolicFormula" 
			class="notification-row notification-section-head"
		>
			<h5>Metabolic Formula</h5>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="metabolicFormulaSubmitted"
					[value]="this.formGroup.value.metabolicFormulaSubmitted"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Metabolic Formula Submitted</h6>
			</div>

			<div class="notification-body">
				Receive daily notifications when a Metabolic Formula form has been submitted.
			</div>
		</div>

		<div class="notification-row nbb">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="metabolicFormulaComments"
					[value]="this.formGroup.value.metabolicFormulaComments"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Metabolic Formula Comments</h6>
			</div>

			<div class="notification-body">
				Receive notifications when a Metabolic Formula comment has been added to an order.
			</div>
		</div>
	</div>

	<!-- CI Repack section -->
	<div *ngIf="hasCiModuleAccess">
		<div 
			id="ciRepack" 
			class="notification-row notification-section-head"
		>
			<h5>CI Repack</h5>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="newCiInvoicesAvailable"
					[value]="this.formGroup.value.newCiInvoicesAvailable"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>New Cardinal Invoices</h6>
			</div>

			<div class="notification-body">
				Receive daily notifications when new Cardinal Invoices are available to create orders.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="newCiOrdersAvailable"
					[value]="this.formGroup.value.newCiOrdersAvailable"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>New Order Requests Available</h6>
			</div>

			<div class="notification-body">
				Receive notifications when new CI Order Requests are available.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ciOrderCommentEmail"
					[value]="this.formGroup.value.ciOrderCommentEmail"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>New Order Comments</h6>
			</div>

			<div class="notification-body">
				Receive notifications when a CI Repack comment has been added to an order.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ciOrderNotSubmitted"
					[value]="this.formGroup.value.ciOrderNotSubmitted"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Order Not Submitted</h6>
			</div>

			<div class="notification-body">
				Receive notifications at 9am, 12pm, and 3pm, regarding any CI Orders that are still in the Pending
				status that have yet to be completed.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ciBatchControlRecord"
					[value]="this.formGroup.value.ciBatchControlRecord"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Batch Control Records</h6>
			</div>

			<div class="notification-body">
				Receive notifications when CI Batch Control Records are available or have been updated and are ready to
				review.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="repackInvoiceEmail"
					[value]="this.formGroup.value.repackInvoiceEmail"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>New Repack Invoices</h6>
			</div>

			<div class="notification-body">
				Receive notifications when new CI Repack Invoices have been created.
			</div>
		</div>

		<div class="notification-row nbb">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ciEndOfWeekMetrics"
					[value]="this.formGroup.value.ciEndOfWeekMetrics"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>End of Week Metrics</h6>
			</div>

			<div class="notification-body">
				Receive notifications every Friday morning showing CI metrics of orders/jobs regarding the progress of
				that order/job.
			</div>
		</div>
	</div>

	<!-- Organization section -->
	<div 
		*ngIf="
			hasOrganizationAccountRequestAccess 
			|| hasOrganizationAccountUpdateAccess
		" 
		id="organization" 
		class="notification-row notification-section-head"
	>
		<h5>Admin</h5>
	</div>

	<div 
		*ngIf="hasOrganizationAccountRequestAccess" 
		class="notification-row"
	>
		<div class="notification-toggle">
			<mat-button-toggle-group 
				formControlName="organizationAccountRequestEmail"
				[value]="this.formGroup.value.organizationAccountRequestEmail"
			>
				<mat-button-toggle 
					[value]="0"
					style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
				>Off</mat-button-toggle>
				<mat-button-toggle [value]="1">Email</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div class="notification-head">
			<h6>Organization Account Request</h6>
		</div>

		<div class="notification-body">
			Receive notifications when changes to Organization Accounts are requested and are ready to review.
		</div>
	</div>

	<div 
		*ngIf="hasOrganizationAccountUpdateAccess" 
		class="notification-row nbb"
	>
		<div class="notification-toggle">
			<mat-button-toggle-group 
				formControlName="organizationAccountExpirationEmail"
				[value]="this.formGroup.value.organizationAccountExpirationEmail"
			>
				<mat-button-toggle 
					[value]="0"
					style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
				>Off</mat-button-toggle>
				<mat-button-toggle [value]="1">Email</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div class="notification-head">
			<h6>Organization Account Expiration</h6>
		</div>

		<div class="notification-body">
			Receive notifications when a Organization Account is within 90 days of expiration and an update request needs to
			be submitted.
			The corresponding Master Account must have "Expiration Notifications" turned on for these notifications to
			be sent for a
			specified account type.
		</div>
	</div>

	<!-- Support section -->
	<div *ngIf="hasSupportAccess">
		<div class="notification-row notification-section-head">
			<h5>Support</h5>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ticketUpdate" 
					[value]="this.formGroup.value.ticketUpdate"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Ticket Updated</h6>
			</div>

			<div class="notification-body">
				Receive notifications when Support Desk Ticket fields are updated.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ticketCreated" 
					[value]="this.formGroup.value.ticketCreated"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Ticket Created</h6>
			</div>

			<div class="notification-body">
				Receive notifications when a new Support Desk Ticket is created.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ticketAssigned" 
					[value]="this.formGroup.value.ticketAssigned"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Ticket Assigned</h6>
			</div>

			<div class="notification-body">
				Receive notifications when a new Support Desk Ticket is assigned to you.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ticketStatusChange"
					[value]="this.formGroup.value.ticketStatusChange"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Ticket Status Change</h6>
			</div>

			<div class="notification-body">
				Receive notifications when a Support Desk Ticket's status is changed.
			</div>
		</div>

		<div class="notification-row nbb">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="ticketCommentAdded"
					[value]="this.formGroup.value.ticketCommentAdded"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Ticket Conversations</h6>
			</div>

			<div class="notification-body">
				Receive notifications when a new comment is added to a Support Desk Ticket conversation.
			</div>
		</div>
	</div>

	<!-- ICQ section -->
	<div *ngIf="hasIcqAccess">
		<div id="icq" class="notification-row notification-section-head">
			<h5>ICQ</h5>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="newSurveyAvailable" 
					[value]="this.formGroup.value.newSurveyAvailable"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>New Survey Available</h6>
			</div>

			<div class="notification-body">
				Receive notifications a new ICQ survey is published.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="surveyCompletionReminder" 
					[value]="this.formGroup.value.surveyCompletionReminder"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Survey Completion Reminder</h6>
			</div>

			<div class="notification-body">
				Receive notifications to that there is a survey that needs completion.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="overdueSurveyReminder" 
					[value]="this.formGroup.value.overdueSurveyReminder"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Overdue Survey Reminder</h6>
			</div>

			<div class="notification-body">
				Receive notifications when a survey is overdue.
			</div>
		</div>

		<div class="notification-row">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="scheduleSiteVisitReminder" 
					[value]="this.formGroup.value.scheduleSiteVisitReminder"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Schedule Site Visit Reminder</h6>
			</div>

			<div class="notification-body">
				Receive notifications to schedule a site visit.
			</div>
		</div>

		<div class="notification-row nbb">
			<div class="notification-toggle">
				<mat-button-toggle-group 
					formControlName="postSiteVisitReminder"
					[value]="this.formGroup.value.postSiteVisitReminder"
				>
					<mat-button-toggle 
						[value]="0"
						style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
					>Off</mat-button-toggle>
					<mat-button-toggle [value]="1">Email</mat-button-toggle>
					<mat-button-toggle [value]="2">Push</mat-button-toggle>
					<mat-button-toggle [value]="3">Both</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			<div class="notification-head">
				<h6>Post Site Visit Reminder</h6>
			</div>

			<div class="notification-body">
				Receive notifications to schedule a post site visit.
			</div>
		</div>
	</div>
	
	<!-- Other section -->
	<div id="other" class="notification-row notification-section-head">
		<h5>Other</h5>
	</div>

	<div class="notification-row nbb">
		<div class="notification-toggle">
			<mat-button-toggle-group 
				formControlName="releaseNotes" 
				[value]="this.formGroup.value.releaseNotes"
			>
				<mat-button-toggle 
					[value]="0"
					style="--mat-standard-button-toggle-selected-state-background-color: grey !important;"
				>Off</mat-button-toggle>
				<mat-button-toggle [value]="1">Email</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div class="notification-head">
			<h6>Release Notes</h6>
		</div>

		<div class="notification-body">
			Receive notifications with release notes of new production installs.
		</div>
	</div>

</form>