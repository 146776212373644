import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SystemMessage } from 'app/core/system-message/system-message-service';
import { PermissionInfoTextVm } from 'app/shared/generated/Administration/Models/PermissionsInfo/PermissionInfoTextVm';

@Component({
    selector: 'pcg-user-role-info-modal',
    templateUrl: './user-role-info-modal.component.html',
    styleUrls: ['./user-role-info-modal.component.scss']
})
export class UserRoleInfoModalComponent implements OnInit {

    @Input() module: BusinessAreaEnum = null;

    businessAreaEnum = BusinessAreaEnum;
    modalTitle: string = '';
    faCheck = faCheck;
    showDefault: boolean = false;
    optionalText: string = '';
    permissionRole = PermissionRole;

    constructor(
        public activeModal: NgbActiveModal
        , private http: HttpClient
    ) { }

    ngOnInit() {
        this.getOptionalText();
        switch (this.module) {
            case BusinessAreaEnum.Admin: this.showDefault = false; break;
            case BusinessAreaEnum.CI: this.showDefault = false; break;
            case BusinessAreaEnum.HEROS: this.showDefault = false; break;
            case BusinessAreaEnum.Inventory: this.showDefault = false; break;
            case BusinessAreaEnum.MetabolicFormula: this.showDefault = false; break;
            case BusinessAreaEnum.NDC: this.showDefault = false; break;
            case BusinessAreaEnum.HelpDesk: this.showDefault = false; break;
            case BusinessAreaEnum.ICQ: this.showDefault = false; break;
            default: this.showDefault = true; break;
        }
        // Get the first word for the modal title.
        this.modalTitle = BusinessAreaEnum.toDescription(this.module)?.split(' ')?.[0];
    }

    getOptionalText() {
        this.http.get(`api/Administration/PermissionsInfo/GetPermissionsTextByModule?module=${Number(this.module)}`)
            .subscribe((vm: PermissionInfoTextVm) => { this.optionalText = vm.text; });
    }
}
