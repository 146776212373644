import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { JwtModule } from "@auth0/angular-jwt";
import { NgbDateNativeAdapter, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgxCaptchaModule } from "ngx-captcha";

import { CiInvoicePdfComponent } from "app/ci-repack/ci-invoices/invoice-pdf/invoice-pdf.component";
import { CiRepackOrderShortOverPrintableComponent } from 'app/ci-repack/ci-repack-order/ci-repack-order-short-over/ci-repack-order-short-over-printable/ci-repack-order-short-over-printable.component';
import { NgbDateNativeParserFormatter } from "app/shared/form-elements/components/date-picker-parser/ngb-date-native-parser-formatter";
import { NavigationService } from "app/shared/navigation/navigation.service";
import { SharedModule } from "app/shared/shared.module";
import { FilterSaveService } from "app/shared/table/services/filter-save.service";
import { CollapsibleTableService } from './collapsible-table/collapsible-table.service';
import { CoreRouting } from "./core.routing";
import { InactivityTimeoutService } from "./inactivity-timeout/inactivity-timeout.service";
import { HttpClientInterceptor } from "./interceptors/http-client-interceptor";
import { LayoutModule } from "./layout/layout.module";
import { SpinnerService } from "./layout/spinner/spinner.service";
import { HelpDeskTicketFeedbackComponent } from './pages/help-desk-ticket-feedback/help-desk-ticket-feedback.component';
import { HerosApplicationComponent } from "./pages/heros-application/heros-application.component";
import { HolidaysComponent } from "./pages/holidays/holidays.component";
import { HomeDeliverySubmitModalComponent } from "./pages/home-delivery/home-delivery-submit-modal/home-delivery-submit-modal.component";
import { HomeDeliveryComponent } from "./pages/home-delivery/home-delivery.component";
import { HomerRegisterComponent } from "./pages/homer-register/homer-register.component";
import { MultiFactorAuthModalComponent } from "./pages/login/multi-factor-auth-modal/multi-factor-auth-modal.component";
import { PfsHelpTicketComponent } from "./pages/pfs-help-ticket/pfs-help-ticket.component";
import { PfsRegisterComponent } from "./pages/pfs-register/pfs-register.component";
import { DonorRegistrationComponent } from "./pages/rxconnects/donor/donor-registration.component";
import { MetaPixelComponent } from "./pages/rxconnects/meta-pixel/meta-pixel.component";
import { PatientApplicationComponent } from "./pages/rxconnects/patient-application/patient-application.component";
import { TechSpecComponent } from "./pages/tech-spec/tech-spec.component";
import { WebReleaseNotesComponent } from "./pages/web-release-notes/web-release-notes.component";
import { PushNotificationModule } from "./push-notification/push-notification.module";
import { GetPasswordComponent } from "./security/get-password/get-password.component";
import { WebAuthnService } from "./security/multi-factor-auth/web-authn.service";
import { SecurityService } from "./security/security.service";
import { SendgridApiService } from "./sendgrid-api/sendgrid-api.service";
import { SystemMessageService } from "./system-message/system-message-service";
import { SystemMessageModule } from "./system-message/system-message.module";
import { IcqSchedulingComponent } from "./pages/icq-scheduling/icq-scheduling.component";
import { InactivityTimeoutComponent } from './inactivity-timeout/inactivity-timeout.component';

export function getToken() { return localStorage.getItem("jwt"); }

/***
 * Contains application-wide, single use components and services.
 * Imported once (in the AppModule) when the app starts and never imported anywhere else.
 */
@NgModule({
	declarations: [
		...CoreRouting.declarations
		, MultiFactorAuthModalComponent
		, GetPasswordComponent
		, HerosApplicationComponent
		, WebReleaseNotesComponent
		, HomeDeliveryComponent
		, HomeDeliverySubmitModalComponent
		, TechSpecComponent
		, HolidaysComponent
		, PfsRegisterComponent
		, HomerRegisterComponent
		, PfsHelpTicketComponent
		, DonorRegistrationComponent
		, PatientApplicationComponent
		, MetaPixelComponent
		, CiInvoicePdfComponent
		, CiRepackOrderShortOverPrintableComponent
		, HelpDeskTicketFeedbackComponent
		, IcqSchedulingComponent
		, InactivityTimeoutComponent
	]
	, imports: [
		...CoreRouting.imports
		, HttpClientModule
		, BrowserModule
		, SystemMessageModule
		, PushNotificationModule
		, LayoutModule
		, SharedModule
		, JwtModule.forRoot({
			config: {
				tokenGetter: getToken
				, allowedDomains: [window.location.host]
			}
		})
		, NgxCaptchaModule
	]
	, exports: [
		...CoreRouting.exports
		, SharedModule
		, LayoutModule
		, HttpClientModule
		, RouterModule
		, SystemMessageModule
		, PushNotificationModule
		, BrowserModule
	]
	, providers: [
		...CoreRouting.providers
		, SystemMessageService
		, SpinnerService
		, SecurityService
		, WebAuthnService
		, InactivityTimeoutService
		, CollapsibleTableService
		, NavigationService
		, FilterSaveService
		, SendgridApiService
		, {
			provide: NgbDateParserFormatter
			, useClass: NgbDateNativeParserFormatter
		}
		, NgbDateNativeAdapter
		, {
			provide: HTTP_INTERCEPTORS
			, useClass: HttpClientInterceptor
			, multi: true
		}
	]
})
export class CoreModule {}
