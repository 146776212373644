<div class="row p-2">
    <span class="col-md-6"></span>
    <div 
        *ngFor="let role of roles" 
        class="col-md text-center mt-auto"
    >{{ permissionRole.toDisplay(role) }}</div>
</div>

<div 
    *ngFor="let sec of secAccess" 
    class="d-flex flex-row p-2 border-bottom"
>
    <div class="d-flex flex-nowrap font-weight-bold" [class]="module === businessAreaEnum.HelpDesk ? 'col-md-5' : 'col-md-2'">
        <span [innerHTML]="sec?.name"></span>
    </div>
    <div 
        class="d-flex flex-nowrap font" 
        [class]="module === businessAreaEnum.HelpDesk ? 'col-md-1' : 'col-md-4'"
        style="color:#808080"
    >
        <span [innerHTML]="sec?.descr"></span>
    </div>
    <div class="col-md-6 d-flex flex-nowrap px-0">
        <div 
            *ngFor="let role of roles" 
            class="col-md text-center my-auto"
        >
            <fa-icon 
                *ngIf="hasRoleAccess(role, sec)" 
                [icon]="faCheck"
            ></fa-icon>
        </div>
    </div>
</div>
