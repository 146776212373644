import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class OrganizationAccountRequestVm {
	organizationAccountRequestId: number;
	organizationAccount?: any;
	organizationId?: number;
	organizationAccountId: number;
	oldFacilityAccountName?: any;
	newFacilityAccountName?: any;
	facilityAccountNameId: number;
	facilityAccountNamePfsId?: number;
	oldAccountNumber?: string;
	newAccountNumber?: string;
	oldExpirationDate?: Date;
	newExpirationDate?: Date;
	oldNotes?: string;
	newNotes?: string;
	oldIsActive?: boolean;
	newIsActive: boolean;
	oldIsGracePeriod?: boolean;
	newIsGracePeriod: boolean;
	oldUploadFilePath?: string;
	newUploadFilePath?: string;
	oldFileName?: string;
	newFileName?: string;
	isNewRequest: boolean;
	isRemoveDoc: boolean;
	isNotification: boolean;
	requestDate?: Date;
	requestBy?: any;
	requestStatus?: any;
	reviewDate?: Date;
	reviewedBy?: any;
	denialExplanation?: string;
	submitButton: boolean;
	file?: any;
	uploadFiles?: any;
	friendlyId?: string;
	facilityId: number;
	accountAdded?: string;
	accountName?: string;
	accountNumber?: string;
	expirationDate?: Date;
	isGracePeriod?: boolean;
	isActive?: boolean;
	uploadFilePath?: string;
	notes?: string;
	pfsIds?: string;
	pfsFacilityAccountRequestIds?: number[];

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'organizationAccountRequestId': new UntypedFormControl(0, [...(validators['organizationAccountRequestId'] ?? []), ...[Validators.required]]),
			'organizationAccount': new UntypedFormControl(null, [...(validators['organizationAccount'] ?? []), ...[]]),
			'organizationId': new UntypedFormControl(null, [...(validators['organizationId'] ?? []), ...[]]),
			'organizationAccountId': new UntypedFormControl(0, [...(validators['organizationAccountId'] ?? []), ...[Validators.required]]),
			'oldFacilityAccountName': new UntypedFormControl(null, [...(validators['oldFacilityAccountName'] ?? []), ...[]]),
			'newFacilityAccountName': new UntypedFormControl(null, [...(validators['newFacilityAccountName'] ?? []), ...[]]),
			'facilityAccountNameId': new UntypedFormControl(0, [...(validators['facilityAccountNameId'] ?? []), ...[Validators.required]]),
			'facilityAccountNamePfsId': new UntypedFormControl(null, [...(validators['facilityAccountNamePfsId'] ?? []), ...[]]),
			'oldAccountNumber': new UntypedFormControl(null, [...(validators['oldAccountNumber'] ?? []), ...[Validators.maxLength(50)]]),
			'newAccountNumber': new UntypedFormControl(null, [...(validators['newAccountNumber'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.pattern("^[A-Za-z0-9 ]*")]]),
			'oldExpirationDate': new UntypedFormControl(null, [...(validators['oldExpirationDate'] ?? []), ...[]]),
			'newExpirationDate': new UntypedFormControl(null, [...(validators['newExpirationDate'] ?? []), ...[]]),
			'oldNotes': new UntypedFormControl(null, [...(validators['oldNotes'] ?? []), ...[Validators.maxLength(1000)]]),
			'newNotes': new UntypedFormControl(null, [...(validators['newNotes'] ?? []), ...[Validators.maxLength(1000)]]),
			'oldIsActive': new UntypedFormControl(null, [...(validators['oldIsActive'] ?? []), ...[]]),
			'newIsActive': new UntypedFormControl(false, [...(validators['newIsActive'] ?? []), ...[]]),
			'oldIsGracePeriod': new UntypedFormControl(null, [...(validators['oldIsGracePeriod'] ?? []), ...[]]),
			'newIsGracePeriod': new UntypedFormControl(false, [...(validators['newIsGracePeriod'] ?? []), ...[]]),
			'oldUploadFilePath': new UntypedFormControl(null, [...(validators['oldUploadFilePath'] ?? []), ...[Validators.maxLength(250)]]),
			'newUploadFilePath': new UntypedFormControl(null, [...(validators['newUploadFilePath'] ?? []), ...[Validators.maxLength(250)]]),
			'oldFileName': new UntypedFormControl(null, [...(validators['oldFileName'] ?? []), ...[]]),
			'newFileName': new UntypedFormControl(null, [...(validators['newFileName'] ?? []), ...[]]),
			'isNewRequest': new UntypedFormControl(false, [...(validators['isNewRequest'] ?? []), ...[]]),
			'isRemoveDoc': new UntypedFormControl(false, [...(validators['isRemoveDoc'] ?? []), ...[]]),
			'isNotification': new UntypedFormControl(false, [...(validators['isNotification'] ?? []), ...[]]),
			'requestDate': new UntypedFormControl(null, [...(validators['requestDate'] ?? []), ...[]]),
			'requestBy': new UntypedFormControl(null, [...(validators['requestBy'] ?? []), ...[]]),
			'requestStatus': new UntypedFormControl(null, [...(validators['requestStatus'] ?? []), ...[]]),
			'reviewDate': new UntypedFormControl(null, [...(validators['reviewDate'] ?? []), ...[]]),
			'reviewedBy': new UntypedFormControl(null, [...(validators['reviewedBy'] ?? []), ...[]]),
			'denialExplanation': new UntypedFormControl(null, [...(validators['denialExplanation'] ?? []), ...[Validators.maxLength(500)]]),
			'submitButton': new UntypedFormControl(false, [...(validators['submitButton'] ?? []), ...[]]),
			'file': new UntypedFormControl(null, [...(validators['file'] ?? []), ...[]]),
			'uploadFiles': new UntypedFormControl([], [...(validators['uploadFiles'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'accountAdded': new UntypedFormControl(null, [...(validators['accountAdded'] ?? []), ...[]]),
			'accountName': new UntypedFormControl(null, [...(validators['accountName'] ?? []), ...[]]),
			'accountNumber': new UntypedFormControl(null, [...(validators['accountNumber'] ?? []), ...[]]),
			'expirationDate': new UntypedFormControl(null, [...(validators['expirationDate'] ?? []), ...[]]),
			'isGracePeriod': new UntypedFormControl(null, [...(validators['isGracePeriod'] ?? []), ...[]]),
			'isActive': new UntypedFormControl(null, [...(validators['isActive'] ?? []), ...[]]),
			'uploadFilePath': new UntypedFormControl(null, [...(validators['uploadFilePath'] ?? []), ...[]]),
			'notes': new UntypedFormControl(null, [...(validators['notes'] ?? []), ...[]]),
			'pfsIds': new UntypedFormControl(null, [...(validators['pfsIds'] ?? []), ...[]]),
			'pfsFacilityAccountRequestIds': new UntypedFormControl([], [...(validators['pfsFacilityAccountRequestIds'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): OrganizationAccountRequestVmFromFg {
		return new OrganizationAccountRequestVmFromFg(fg);
	}
}

export class OrganizationAccountRequestVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get organizationAccountRequestId(): number {
		return this.fg.get('organizationAccountRequestId').value;
	}
	set organizationAccountRequestId(val) {
		this.fg.get('organizationAccountRequestId').setValue(val);
	}

	get organizationAccount(): any {
		return this.fg.get('organizationAccount').value;
	}
	set organizationAccount(val) {
		this.fg.get('organizationAccount').setValue(val);
	}

	get organizationId(): number {
		return this.fg.get('organizationId').value;
	}
	set organizationId(val) {
		this.fg.get('organizationId').setValue(val);
	}

	get organizationAccountId(): number {
		return this.fg.get('organizationAccountId').value;
	}
	set organizationAccountId(val) {
		this.fg.get('organizationAccountId').setValue(val);
	}

	get oldFacilityAccountName(): any {
		return this.fg.get('oldFacilityAccountName').value;
	}
	set oldFacilityAccountName(val) {
		this.fg.get('oldFacilityAccountName').setValue(val);
	}

	get newFacilityAccountName(): any {
		return this.fg.get('newFacilityAccountName').value;
	}
	set newFacilityAccountName(val) {
		this.fg.get('newFacilityAccountName').setValue(val);
	}

	get facilityAccountNameId(): number {
		return this.fg.get('facilityAccountNameId').value;
	}
	set facilityAccountNameId(val) {
		this.fg.get('facilityAccountNameId').setValue(val);
	}

	get facilityAccountNamePfsId(): number {
		return this.fg.get('facilityAccountNamePfsId').value;
	}
	set facilityAccountNamePfsId(val) {
		this.fg.get('facilityAccountNamePfsId').setValue(val);
	}

	get oldAccountNumber(): string {
		return this.fg.get('oldAccountNumber').value;
	}
	set oldAccountNumber(val) {
		this.fg.get('oldAccountNumber').setValue(val);
	}

	get newAccountNumber(): string {
		return this.fg.get('newAccountNumber').value;
	}
	set newAccountNumber(val) {
		this.fg.get('newAccountNumber').setValue(val);
	}

	get oldExpirationDate(): Date {
		return this.fg.get('oldExpirationDate').value;
	}
	set oldExpirationDate(val) {
		this.fg.get('oldExpirationDate').setValue(val);
	}

	get newExpirationDate(): Date {
		return this.fg.get('newExpirationDate').value;
	}
	set newExpirationDate(val) {
		this.fg.get('newExpirationDate').setValue(val);
	}

	get oldNotes(): string {
		return this.fg.get('oldNotes').value;
	}
	set oldNotes(val) {
		this.fg.get('oldNotes').setValue(val);
	}

	get newNotes(): string {
		return this.fg.get('newNotes').value;
	}
	set newNotes(val) {
		this.fg.get('newNotes').setValue(val);
	}

	get oldIsActive(): boolean {
		return this.fg.get('oldIsActive').value;
	}
	set oldIsActive(val) {
		this.fg.get('oldIsActive').setValue(val);
	}

	get newIsActive(): boolean {
		return this.fg.get('newIsActive').value;
	}
	set newIsActive(val) {
		this.fg.get('newIsActive').setValue(val);
	}

	get oldIsGracePeriod(): boolean {
		return this.fg.get('oldIsGracePeriod').value;
	}
	set oldIsGracePeriod(val) {
		this.fg.get('oldIsGracePeriod').setValue(val);
	}

	get newIsGracePeriod(): boolean {
		return this.fg.get('newIsGracePeriod').value;
	}
	set newIsGracePeriod(val) {
		this.fg.get('newIsGracePeriod').setValue(val);
	}

	get oldUploadFilePath(): string {
		return this.fg.get('oldUploadFilePath').value;
	}
	set oldUploadFilePath(val) {
		this.fg.get('oldUploadFilePath').setValue(val);
	}

	get newUploadFilePath(): string {
		return this.fg.get('newUploadFilePath').value;
	}
	set newUploadFilePath(val) {
		this.fg.get('newUploadFilePath').setValue(val);
	}

	get oldFileName(): string {
		return this.fg.get('oldFileName').value;
	}
	set oldFileName(val) {
		this.fg.get('oldFileName').setValue(val);
	}

	get newFileName(): string {
		return this.fg.get('newFileName').value;
	}
	set newFileName(val) {
		this.fg.get('newFileName').setValue(val);
	}

	get isNewRequest(): boolean {
		return this.fg.get('isNewRequest').value;
	}
	set isNewRequest(val) {
		this.fg.get('isNewRequest').setValue(val);
	}

	get isRemoveDoc(): boolean {
		return this.fg.get('isRemoveDoc').value;
	}
	set isRemoveDoc(val) {
		this.fg.get('isRemoveDoc').setValue(val);
	}

	get isNotification(): boolean {
		return this.fg.get('isNotification').value;
	}
	set isNotification(val) {
		this.fg.get('isNotification').setValue(val);
	}

	get requestDate(): Date {
		return this.fg.get('requestDate').value;
	}
	set requestDate(val) {
		this.fg.get('requestDate').setValue(val);
	}

	get requestBy(): any {
		return this.fg.get('requestBy').value;
	}
	set requestBy(val) {
		this.fg.get('requestBy').setValue(val);
	}

	get requestStatus(): any {
		return this.fg.get('requestStatus').value;
	}
	set requestStatus(val) {
		this.fg.get('requestStatus').setValue(val);
	}

	get reviewDate(): Date {
		return this.fg.get('reviewDate').value;
	}
	set reviewDate(val) {
		this.fg.get('reviewDate').setValue(val);
	}

	get reviewedBy(): any {
		return this.fg.get('reviewedBy').value;
	}
	set reviewedBy(val) {
		this.fg.get('reviewedBy').setValue(val);
	}

	get denialExplanation(): string {
		return this.fg.get('denialExplanation').value;
	}
	set denialExplanation(val) {
		this.fg.get('denialExplanation').setValue(val);
	}

	get submitButton(): boolean {
		return this.fg.get('submitButton').value;
	}
	set submitButton(val) {
		this.fg.get('submitButton').setValue(val);
	}

	get file(): any {
		return this.fg.get('file').value;
	}
	set file(val) {
		this.fg.get('file').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get accountAdded(): string {
		return this.fg.get('accountAdded').value;
	}
	set accountAdded(val) {
		this.fg.get('accountAdded').setValue(val);
	}

	get accountName(): string {
		return this.fg.get('accountName').value;
	}
	set accountName(val) {
		this.fg.get('accountName').setValue(val);
	}

	get accountNumber(): string {
		return this.fg.get('accountNumber').value;
	}
	set accountNumber(val) {
		this.fg.get('accountNumber').setValue(val);
	}

	get expirationDate(): Date {
		return this.fg.get('expirationDate').value;
	}
	set expirationDate(val) {
		this.fg.get('expirationDate').setValue(val);
	}

	get isGracePeriod(): boolean {
		return this.fg.get('isGracePeriod').value;
	}
	set isGracePeriod(val) {
		this.fg.get('isGracePeriod').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get uploadFilePath(): string {
		return this.fg.get('uploadFilePath').value;
	}
	set uploadFilePath(val) {
		this.fg.get('uploadFilePath').setValue(val);
	}

	get notes(): string {
		return this.fg.get('notes').value;
	}
	set notes(val) {
		this.fg.get('notes').setValue(val);
	}

	get pfsIds(): string {
		return this.fg.get('pfsIds').value;
	}
	set pfsIds(val) {
		this.fg.get('pfsIds').setValue(val);
	}
}
