import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Subscription } from 'rxjs';

import { PcgSelectComponent } from 'app/shared/form-elements/components/pcg-select-component/select.component';
import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { PfsHelpTicketVm } from 'app/shared/generated/Models/Public/PfsHelpTicketVm';
import { pcgSettings } from 'app/shared/generated/pcg-settings';

@Component({
    selector: 'app-pfs-help-ticket',
    templateUrl: './pfs-help-ticket.component.html',
    styleUrls: ['./pfs-help-ticket.component.scss']
})
export class PfsHelpTicketComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('facilitySelect', { static: false }) facilitySelect: PcgSelectComponent;
    @ViewChild('categorySelect', { static: false }) categorySelect: PcgSelectComponent;
    @ViewChild('prioritySelect', { static: false }) prioritySelect: PcgSelectComponent;
    @ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;

    siteKey: string = environment.siteKey; // should pick up environment specific siteKey

    public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;	
	public recaptcha: any = null;
    public execute: any;
    emptyCaptcha: boolean = true;
    honeypotControl;

    subscriptions: Subscription = new Subscription();
    formGroup = PfsHelpTicketVm.Form;
    model = PfsHelpTicketVm.GetModel(this.formGroup);

    isSuccess = false;
    
    pfsUrl = "";
    facilityName = "";
    categoryName = "Login Issues";
    priorityName = "High";
    
    constructor(
        private ms: SystemMessageService
        , private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.model.categoryId = 4;
        this.model.categoryName = this.categoryName;
        this.model.priorityId = 1;
        this.model.priorityName = this.priorityName;
        this.setEnv(); 
        this.setUpHoneyPot();
        this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
    }

    ngAfterViewInit(): void {
        this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

    setUpHoneyPot() {
		this.formGroup.controls['honeyPot'].setValidators(this.requireFalse());
		this.formGroup.updateValueAndValidity();
	}

    requireFalse() : ValidatorFn {
		return (control : AbstractControl) : ValidationErrors | null => {
			if (control.value === true) { return { honeyPot: true}; }
			return null;
		}
	}

    handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

    handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
        this.execute = setInterval(() => {
			this.captchaElem?.execute();
            this.captchaElem?.getResponse() !== '' 
                ? this.emptyCaptcha = false 
                : this.emptyCaptcha = true;
		}, 2000);
    }

    handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
    }

    handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
    }

    setEnv() {
		if (pcgSettings.mode === 'Development') { this.pfsUrl = "https://localhost:44384/"; } 
        else if (pcgSettings.mode === 'Demo') { this.pfsUrl = "https://demo.fdohcentralpharmacy.com/"; } 
        else { this.pfsUrl = "https://fdohcentralpharmacy.com/"; }
	}

    sanitize(email: string) {
        var strTempString = email.trim();
        strTempString = strTempString.replace("'", "");
        strTempString = strTempString.replace("--", "");
        strTempString = strTempString.replace("/", "");
        strTempString = strTempString.replace("&", "");
        strTempString = strTempString.replace("?", "");
        strTempString = strTempString.replace(":", "");
        strTempString = strTempString.replace(",", "");
        strTempString = strTempString.replace(";", "");
        strTempString = strTempString.replace("NULL", "");
        strTempString = strTempString.replace("xp_", "");
        strTempString.replace(/[\n\r]+/g, '');
        strTempString.replace('\\', '');
        this.formGroup.controls['email'].setValue(strTempString);
    }

    categoryChange() {
        this.categoryName = this.categorySelect.text;
        this.model.categoryName = this.categoryName;
        this.formGroup.controls['categoryName'].setValue(this.categoryName);        
    }

    priorityChange() {
        this.priorityName = this.prioritySelect.text;
        this.model.priorityName = this.priorityName;
        this.formGroup.controls['priorityName'].setValue(this.priorityName);
    }

    facilityChange() {
        this.facilityName = this.facilitySelect.text;
        this.model.facilityName = this.facilityName;
        this.formGroup.controls['facilityName'].setValue(this.facilityName);
    }

    onSubmit() {
        if (this.formGroup.valid) {            
            this.subscriptions.add(this.ms.getHttpObservable(this, 'api/PublicPfsHelpTicket/SubmitPfsHelpTicket', this.formGroup)
                .subscribe(msg => {
                    if (msg.isSuccessful) {
                        this.isSuccess = true;
                        this.formGroup.reset();
                    } else { this.isSuccess = false; }
                 }));
        } else { 
            validateForm(this.formGroup); 
            this.isSuccess = false;
        }
    }

    ngOnDestroy() { 
        this.subscriptions.unsubscribe(); 
		clearInterval(this.execute);
    }
}
