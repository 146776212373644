import { UserService } from 'app/shared/services/user/user.service';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { SecurityService } from '../security/security.service';
import { PermissionRole } from '../enums/generated/PermissionRole';

export const getInventoryNav: () => NavRoute = () => {
	const emptyRoute: NavRoute = { name: "" };
	const user = UserService.getUser();
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.

	if (user == null) return emptyRoute;
	if (user.systemInventory == null || user.systemInventory.length === 0) return emptyRoute;

	const navItems = user.systemInventory.map<NavRoute>((sysInv) => (
		{
			name: sysInv.name.length > 25 ? sysInv.name.substring(0, 25) + "..." : sysInv.name,
			href: `inv/${sysInv.name}/${sysInv.sysInvInventoryId}/products`,
			children:
				[
					{
						name: 'Products', 
						path: `inv/${sysInv.name}/${sysInv.sysInvInventoryId}/products`,
					},
					{
						name: 'Replenishments',
						path: `inv/${sysInv.name}/${sysInv.sysInvInventoryId}/replenishments`,
					},
					{
						name: 'Reconciliations', 
						path: `inv/${sysInv.name}/${sysInv.sysInvInventoryId}/reconciliations`
					}
				]
		})
	);

	const viewAll = { name: 'View All Inventories', exact: true, path: 'inv/list' }

	const navRoute: NavRoute = {
		name: 'Inventory',
		href: 'inv/list',
		exact: true,
		children: [
			{
				name: 'Inventories',
				href: 'inv/list',
				children: [...navItems.splice(0, 5), viewAll],
				featureFlag: true
			},
			{
				name: "EPCIS",
				exact: true,
				path: "inv/epcis-list"
			},
			{
				name: 'Admin Settings'
				, href: 'inv/admin/settings'
				, moduleAccess: [
					{
						modules: [ BusinessAreaEnum.SysInv ]
						, permissionRoles: sec.setMinRole(p.UserAdmin)
					}
				]
				, children: [
					{
						name: 'Verification Settings'
						, exact: true
						, path: 'inv/admin/verification-settings'
						, moduleAccess: [
							{
								modules: [ BusinessAreaEnum.SysInv ]
								, permissionRoles: [ p.SystemAdmin ]
							}
						]
					}
				]
			}
		]
	}

	return navRoute;
};